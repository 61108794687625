import React, { useState, useEffect } from 'react';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper';

const GiveCategory = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const response = await apiCallFetch('/categories/get', 'GET');
                if (response.status) {
                    setCategories(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError('Error fetching categories');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleUpdate = (category) => {
        navigate('/add-category', { state: { category } });
    };

    const handleDeleteCategory = async (categoryId) => {
        console.log(categoryId, '------------');

        const isConfirmed = window.confirm('Are you sure you want to delete this category?');
        if (!isConfirmed) return;

        try {
            const response = await apiCallFetch(`/categories/delete/${categoryId}`, 'DELETE');
            if (response.status) {
                setCategories((prevCategories) => prevCategories.filter((category) => category._id !== categoryId));
            } else {
                alert(response.message);
            }
        } catch (error) {
            alert(error?.response?.data?.message || 'Error deleting category');
            console.error('Error deleting category:', error);
        }
    };

    const LoadingSpinner = () => (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4 py-2">
                <h1 className="text-2xl font-bold">Categories</h1>
                <button
                    onClick={() => navigate('/add-category')} // Navigate to the desired route
                    className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300 flex items-center justify-center ml-auto"
                    title="Add Category"
                >
                    <FaPlus className="mr-2 text-lg" />
                    <span className="text-sm font-semibold">Add Category</span>
                </button>

            </div>

            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {categories.map((category) => (
                        <div key={category._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col space-y-4">
                            <div className="flex justify-between items-center">
                                <h2 className="font-bold text-lg text-gray-800">{category.name}</h2>
                                <div className="flex space-x-2">
                                    <button onClick={() => handleUpdate(category)} className="text-gray-600 hover:text-blue-500">
                                        <FaEdit />
                                    </button>
                                    <button onClick={() => handleDeleteCategory(category._id)} className="text-gray-600 hover:text-red-500">
                                        <FaTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GiveCategory;
