// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { apiCallFetch } from '../api/apiHelper'; // Your API helper

// const AdminDashboard = () => {
//     const navigate = useNavigate();
//     const [dashboardData, setDashboardData] = useState({
//         totalQuizes: 0,
//         totalQuizesAttempt: 0,
//         totalCompanies: 0,
//     });

//     useEffect(() => {
//         const fetchDashboardData = async () => {
//             try {
//                 const response = await apiCallFetch('/admin/get-dashbord', 'GET');
//                 if (response.success) {
//                     setDashboardData(response.data);
//                 } else {
//                     console.error('Failed to fetch dashboard data');
//                 }
//             } catch (error) {
//                 console.error('Error fetching dashboard data:', error);
//             }
//         };

//         fetchDashboardData();
//     }, []);

//     const handleNavigate = (path) => {
//         navigate(path);
//     };

//     return (
//         <div style={dashboardContainerStyle}>
//             <h1 className='text-3xl font-bold mb-8 text-white'>Admin Dashboard</h1>

//             <div style={cardContainerStyle}>
//                 {/* Total Chapters Card */}
//                 <div style={cardStyle}>
//                     <div style={iconStyle}>📚</div>
//                     <h2 style={titleStyle}>Total Chapters</h2>
//                     <p style={textStyle}>{dashboardData.totalCompanies}</p>
//                     <button style={buttonStyle} onClick={() => handleNavigate('/company-master')}>
//                         View Chapters
//                     </button>
//                 </div>

//                 {/* Total Quizzes Card */}
//                 <div style={cardStyle}>
//                     <div style={iconStyle}>📝</div>
//                     <h2 style={titleStyle}>Total Quizzes</h2>
//                     <p style={textStyle}>{dashboardData.totalQuizes}</p>
//                     <button style={buttonStyle} onClick={() => handleNavigate('/quiz-master')}>
//                         View Quizzes
//                     </button>
//                 </div>

//                 {/* Total Quizzes Attempted Card */}
//                 <div style={cardStyle}>
//                     <div style={iconStyle}>🎯</div>
//                     <h2 style={titleStyle}>Quiz Attempted</h2>
//                     <p style={textStyle}>{dashboardData.totalQuizesAttempt}</p>
//                     <button style={buttonStyle} onClick={() => handleNavigate('/quiz-report')}>
//                         View Reports
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // CSS styles as JavaScript objects
// const dashboardContainerStyle = {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//     backgroundColor: '#534b9d',
//     minHeight: '100vh',
// };

// const headerStyle = {
//     fontSize: '32px',
//     fontWeight: 'bold',
//     marginBottom: '20px',
// };

// const cardContainerStyle = {
//     display: 'flex',
//     justifyContent: 'space-between',
//     flexWrap: 'wrap',
// };

// const cardStyle = {
//     backgroundColor: '#fff',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     padding: '20px',
//     borderRadius: '8px',
//     width: 'calc(30% - 10px)',
//     marginBottom: '20px',
//     textAlign: 'center',
//     flex: '1 1 200px',
// };

// const iconStyle = {
//     fontSize: '40px',
//     marginBottom: '10px',
//     color: '#3f51b5',
// };

// const titleStyle = {
//     fontSize: '20px',
//     color: '#333',
//     fontWeight: 'bold',
//     marginBottom: '10px',
// };

// const textStyle = {
//     fontSize: '16px',
//     color: '#666',
//     marginBottom: '15px',
// };

// const buttonStyle = {
//     padding: '10px 20px',
//     backgroundColor: '#3f51b5',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     fontSize: '14px',
// };

// export default AdminDashboard;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper'; // Your API helper
import '../styles/give.css';

const AdminDashboard = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({
        totalQuizes: 0,
        totalQuizesAttempt: 0,
        totalCompanies: 0,
    });
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchDashboardData = async () => {
            setLoading(true);
            try {
                const response = await apiCallFetch('/admin/get-dashbord', 'GET');
                if (response.success) {
                    setDashboardData(response.data);
                } else {
                    console.error('Failed to fetch dashboard data');
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    const handleNavigate = (path) => {
        navigate(path);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-[#534B9D]">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
                    <p className="mt-4 text-white font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='contant-bg' style={{ fontFamily: 'Arial, sans-serif', minHeight: '100vh' }}>
                <h1 style={{ fontSize: '32px', padding: '30px 24px 16px', fontWeight: 'bold', marginBottom: '20px', color: 'white' }}>
                    Admin Dashboard
                </h1>

                <div className='chapter-dashboard'>
                    {/* Total Chapters Card */}
                    <div className='chapter-call'>
                        <div>
                            <div style={iconStyle}>📚</div>
                            <h2 style={titleStyle}>Total Chapters</h2>
                            <p style={textStyle}>{dashboardData.totalCompanies}</p>
                            <button style={buttonStyle} onClick={() => handleNavigate('/company-master')}>
                                View Chapters
                            </button>
                        </div>
                    </div>

                    {/* Total Quizzes Card */}
                    <div className='chapter-call'>
                        <div>
                            <div style={iconStyle}>📝</div>
                            <h2 style={titleStyle}>Total Quizzes</h2>
                            <p style={textStyle}>{dashboardData.totalQuizes}</p>
                            <button style={buttonStyle} onClick={() => handleNavigate('/quiz-master')}>
                                View Quizzes
                            </button>
                        </div>
                    </div>

                    {/* Total Quizzes Attempted Card */}
                    <div className='chapter-call'>
                        <div>
                            <div style={iconStyle}>🎯</div>
                            <h2 style={titleStyle}>Quiz Attempted</h2>
                            <p style={textStyle}>{dashboardData.totalQuizesAttempt}</p>
                            <button style={buttonStyle} onClick={() => handleNavigate('/quiz-report')}>
                                View Reports
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="coppyright">© 2024 – 2025 <a target="_blank" href="https://perceptioncare.com/">Perceptioncare</a>. All rights reserved.</div>
        </>
    );

};

// CSS styles as JavaScript objects
const iconStyle = {
    fontSize: '40px',
    marginBottom: '10px',
    color: '#3f51b5',
};

const titleStyle = {
    fontSize: '20px',
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '10px',
};

const textStyle = {
    fontSize: '16px',
    color: '#666',
    marginBottom: '15px',
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
};

export default AdminDashboard;
