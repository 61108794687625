// import React, { useEffect, useState } from 'react';
// import { apiCallFetch } from '../api/apiHelper'; // Adjust this path if needed
// import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa'; // Optional icons
// import { useNavigate } from 'react-router-dom';

// function QuizMaster() {
//     const [quizData, setQuizData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedQuizzes, setExpandedQuizzes] = useState({}); // Track expanded state of quizzes

//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchQuizData = async () => {
//             setLoading(true);
//             try {
//                 const response = await apiCallFetch('/quiz/get', 'GET');
//                 setQuizData(response.data); // Set the fetched quiz data
//             } catch (err) {
//                 setError('Failed to load quiz data');
//                 console.error('API Error:', err);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchQuizData();
//     }, []);

//     // Toggle function for expanding/collapsing questions in a quiz
//     const toggleQuizExpansion = (quizId) => {
//         setExpandedQuizzes((prevExpanded) => ({
//             ...prevExpanded,
//             [quizId]: !prevExpanded[quizId],
//         }));
//     };

//     const handleEdit = (quiz) => {
//         navigate('/add-quiz', { state: { quiz } }); // Edit functionality
//     };

//     const handleDelete = async (quizId) => {
//         if (window.confirm("Are you sure you want to delete this quiz?")) {
//             try {
//                 await apiCallFetch(`/quiz/delete/${quizId}`, 'PUT'); // Adjust as per your API
//                 setQuizData(quizData.filter(quiz => quiz._id !== quizId));
//             } catch (err) {
//                 console.error("Error deleting quiz:", err);
//                 setError("Failed to delete the quiz.");
//             }
//         }
//     };


//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <div className="loader"></div>
//                 <p className="text-lg text-gray-500">Loading...</p>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
//             </div>
//         );
//     }

//     return (
//         <div className="container mx-auto px-4 py-8">
//             <div className="flex justify-between items-center mb-6">
//                 <h1 className="text-3xl font-bold">Quiz Master</h1>
//                 <button
//                     onClick={() => navigate('/add-quiz')}
//                     className="bg-blue-500 text-white p-2 rounded flex items-center hover:bg-blue-600 transition"
//                 >
//                     <FaPlus className="mr-2" /> Add Quiz
//                 </button>
//             </div>

//             {quizData.length > 0 ? (
//                 quizData.map((quiz) => (
//                     <div key={quiz._id} className="mb-8 p-6 border border-gray-200 rounded-lg shadow-lg relative">
//                         <h2 className="text-2xl font-semibold text-gray-800">{quiz.name}</h2>
//                         <p className="text-gray-600 mt-2">{quiz.description}</p>

//                         <div className="absolute top-2 right-2 flex space-x-2">
//                             <button onClick={() => handleEdit(quiz)} className="text-blue-500 hover:text-blue-700">
//                                 <FaEdit />
//                             </button>
//                             <button onClick={() => handleDelete(quiz._id)} className="text-red-500 hover:text-red-700">
//                                 <FaTrash />
//                             </button>
//                         </div>

//                         <button
//                             onClick={() => toggleQuizExpansion(quiz._id)}
//                             className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
//                         >
//                             {expandedQuizzes[quiz._id] ? 'Hide Questions' : 'Show Questions'}
//                         </button>

//                         {expandedQuizzes[quiz._id] && ( // Conditionally render questions
//                             <>
//                                 <h3 className="text-lg font-semibold mt-4 text-blue-500">Topics:</h3>
//                                 <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
//                                     {quiz.topics.map((topic) => (
//                                         <li key={topic._id} className="my-1">{topic.name}</li>
//                                     ))}
//                                 </ul>
//                                 <h3 className="text-lg font-semibold mt-4 text-blue-500">Questions:</h3>
//                                 {quiz.questions.map((q, index) => (
//                                     <div key={q._id} className="mt-4 bg-gray-50 p-4 rounded-lg">
//                                         <p className="text-gray-800">
//                                             <strong>Q{index + 1}:</strong> {q.question.question}
//                                         </p>
//                                         <ul className="list-decimal list-inside ml-6 mt-2">
//                                             {q.question.options.map((option) => (
//                                                 <li key={option._id} className={`my-1 ${option.isCorrect ? 'text-green-600 font-semibold' : 'text-gray-700'}`}>
//                                                     {option.text} {option.isCorrect ? '(Correct)' : ''}
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                         <p className="text-gray-500 mt-2 text-sm">Explanation: {q.question.explanation}</p>
//                                     </div>
//                                 ))}
//                             </>
//                         )}
//                     </div>
//                 ))
//             ) : (
//                 <p className="text-center text-gray-500">No quizzes available.</p>
//             )}
//         </div>
//     );
// }

// export default QuizMaster;


import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper'; // Adjust this path if needed
import { FaPlus, FaEdit, FaTrash, FaEyeSlash, FaEye } from 'react-icons/fa'; // Optional icons
import { useNavigate } from 'react-router-dom';

function QuizMaster() {
    const [quizData, setQuizData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedQuizzes, setExpandedQuizzes] = useState({}); // Track expanded state of quizzes

    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuizData = async () => {
            setLoading(true);
            try {
                const response = await apiCallFetch('/quiz/get', 'GET');
                setQuizData(response.data); // Set the fetched quiz data
            } catch (err) {
                setError('Failed to load quiz data');
                console.error('API Error:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchQuizData();
    }, []);

    // Toggle function for expanding/collapsing questions in a quiz
    const toggleQuizExpansion = (quizId) => {
        setExpandedQuizzes((prevExpanded) => ({
            ...prevExpanded,
            [quizId]: !prevExpanded[quizId],
        }));
    };

    const handleEdit = (quiz) => {
        navigate('/add-quiz', { state: { quiz } }); // Edit functionality
    };

    const handleDelete = async (quizId) => {
        if (window.confirm("Are you sure you want to delete this quiz?")) {
            try {
                await apiCallFetch(`/quiz/delete/${quizId}`, 'PUT'); // Adjust as per your API
                setQuizData(quizData.filter(quiz => quiz._id !== quizId));
            } catch (err) {
                console.error("Error deleting quiz:", err);
                setError("Failed to delete the quiz.");
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }


    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800">Quiz Master</h1>
                <button
                    onClick={() => navigate('/add-quiz')}
                    className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300 flex items-center justify-center h-12"
                    title="Add Quiz"
                >
                    <FaPlus className="mr-2 text-lg" />
                    <span className="text-sm font-semibold">Add Quiz</span>
                </button>

            </div>

            {quizData.length > 0 ? (
                quizData.map((quiz) => (
                    <div key={quiz._id} className="mb-6 p-6 border border-gray-300 rounded-lg shadow-md bg-white relative">
                        <h2 className="text-2xl font-semibold text-gray-800">{quiz.name}</h2>
                        <p className="text-gray-600 mt-2">{quiz.description}</p>

                        <div className="absolute top-2 right-2 flex space-x-2">
                            <button onClick={() => handleEdit(quiz)}>
                                <FaEdit />
                            </button>
                            <button onClick={() => handleDelete(quiz._id)}>
                                <FaTrash />
                            </button>
                        </div>

                        <button
                            onClick={() => toggleQuizExpansion(quiz._id)}
                            className="flex mt-4 items-center justify-center bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
                            title={expandedQuizzes[quiz._id] ? 'Hide Questions' : 'Show Questions'}
                        >
                            {expandedQuizzes[quiz._id] ? (
                                <>
                                    <FaEyeSlash className="mr-2 text-lg" />
                                    <span className="text-sm font-semibold">Hide Questions</span>
                                </>
                            ) : (
                                <>
                                    <FaEye className="mr-2 text-lg" />
                                    <span className="text-sm font-semibold">Show Questions</span>
                                </>
                            )}
                        </button>

                        {expandedQuizzes[quiz._id] && ( // Conditionally render questions
                            <>
                                <div className="mt-6">
                                    <h3 className="text-lg font-semibold text-blue-600">Topics:</h3>
                                    <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                                        {quiz.topics.map((topic) => (
                                            <li key={topic._id} className="my-1">{topic.name}</li>
                                        ))}
                                    </ul>

                                    <h3 className="text-lg font-semibold text-blue-600 mt-6">Questions:</h3>
                                    {quiz.questions.map((q, index) => (
                                        <div key={q._id} className="mt-4 bg-gray-50 p-4 rounded-lg shadow-sm">
                                            <p className="text-gray-800">
                                                <strong>Q{index + 1}:</strong> {q.question.question}
                                            </p>
                                            <ul className="list-decimal list-inside ml-6 mt-2">
                                                {q.question.options.map((option) => (
                                                    <li
                                                        key={option._id}
                                                        className={`my-1 ${option.isCorrect ? 'text-green-600 font-semibold' : 'text-gray-700'}`}
                                                    >
                                                        {option.text} {option.isCorrect ? '(Correct)' : ''}
                                                    </li>
                                                ))}
                                            </ul>
                                            <p className="text-gray-500 mt-2 text-sm">Explanation: {q.question.explanation}</p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                ))
            ) : (
                <p className="text-center text-gray-500">No quizzes available.</p>
            )}
        </div>
    );
}

export default QuizMaster;
