import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCallFetch, baseURL } from "../api/apiHelper";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const QuizCard = ({ quiz, onClick }) => (
  <div
    onClick={onClick}
    className="flex items-center bg-white rounded-3xl px-3 py-2 hover:shadow-lg transition-all cursor-pointer border border-gray-300"
  >
    <div
      className={`w-16 h-16 rounded-2xl flex items-center justify-center mr-2 ${quiz?.topics[0]?.name?.toLowerCase()?.includes("statistics")
        ? "bg-purple-100"
        : "bg-pink-100"
        }`}
    >
      {quiz?.topics[0]?.name?.charAt(0)?.toUpperCase()}
    </div>

    <div className="flex-1">
      <h2 className="text-l font-bold text-gray-900">{quiz.name}</h2>
      <div className="flex text-sm items-center gap-2 text-gray-500">
        <span>{quiz?.topics[0]?.name}</span>
        <span>•</span>
        <span>{quiz.totalQuestions} Questions</span>
      </div>
    </div>
    <div className="text-purple-600">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  </div>
);

const companyID = JSON.parse(localStorage.getItem("user"))?.company?._id;

const Quizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);

  const role = JSON.parse(localStorage.getItem("user"))?.role;

  const [quizAttempts, setQuizAttempts] = useState([]);
  const [userToken, setUserToken] = useState(
    JSON.parse(localStorage.getItem("user"))?.token
  );

  useEffect(() => {
    const fetchData = async () => {
      isLoading(true);
      try {
        const quizzesData =
          role === "mentor"
            ? await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET")
            : await apiCallFetch("/quiz/get-quiz-by-mentee", "GET");

        if (quizzesData.status) {
          setQuizzes(quizzesData.data);
        }

        // Fetch stats
        const statsData = await apiCallFetch("/quiz/stats/user", "GET");
        if (statsData.success) {
          setStats(statsData.data);
        }

        // Fetch quiz attempts
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          baseURL + "/api/quiz/attempt/user",
          requestOptions
        );
        const result = await response.json();
        setQuizAttempts(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
          <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen bg-olive-green p-5"
      style={{ minHeight: `calc(100vh - 64px)` }}
    >
      <h2 className="text-xl font-bold mb-4 text-white">Quizzes</h2>
      <div className="max-w-5xl mx-auto">

        {/* Quiz Report Section */}
        <div className="mt-8">
          <h2 className="text-xl text-white font-bold mb-4 text-center">
            Quiz Attempts
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {quizAttempts.map((attempt, index) => (
              <QuizAttemptCard key={index} attempt={attempt} />
            ))}
          </div>
        </div>

        {stats && quizzes.length !== 0 && (
          <div className="mb-4 flex justify-between items-center">
            <h2 className="text-xl font-bold text-white">Recent Quizzes</h2>

            <button
              onClick={() => navigate("/all-quizzes")}
              className="text-white font-semibold hover:text-white"
            >
              See All
            </button>
          </div>
        )}

        <div className="space-y-4">
          {role === "mentor"
            ? quizzes.slice(0, 5).map((quiz) => (
              <QuizCard
                key={quiz._id}
                quiz={quiz}
                onClick={() => navigate(`/quiz/${quiz._id}`)}
              />
            ))
            : quizzes.slice(0, 5).map((quiz) =>
              quiz.quizzes.map((q) => (
                <QuizCard
                  key={q._id}
                  quiz={q}
                  onClick={() => navigate(`/quiz/${q._id}`)}
                />
              ))
            )}
        </div>
      </div>
    </div>
  );
};

const QuizAttemptCard = ({ attempt }) => {
  const [showQuestions, setShowQuestions] = useState(false);

  return (
    <div className="bg-white rounded-3xl shadow-md overflow-hidden mb-4">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{attempt.quizName}</div>
        {attempt?.attempts?.map((a, index) => (
          <div key={index} className="mb-4 border-b pb-4">
            <p className="text-gray-700 text-base">
              Attempt on {new Date(a.startTime).toLocaleString()}
            </p>
            <div className="px-6 py-4 border-t">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="font-medium">Score:</p>
                  <p>
                    {a.score} / {a.totalPossibleScore}
                  </p>
                </div>
                <div>
                  <p className="font-medium">Percentage:</p>
                  <p>{a.percentageScore.toFixed(2)}%</p>
                </div>
                <div>
                  <p className="font-medium">Status:</p>
                  <p
                    className={`font-medium ${a.passed ? "text-green-500" : "text-red-500"
                      }`}
                  >
                    {a.passed ? "Passed" : "Failed"}
                  </p>
                </div>
                <div>
                  <p className="font-medium">Time Spent:</p>
                  <p>{a.timeSpent ? a.timeSpent + " seconds" : "N/A"}</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => setShowQuestions(!showQuestions)}
              className="flex mt-4 items-center justify-center bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
              title={showQuestions ? 'Hide Questions' : 'Show Questions'}
            >
              {showQuestions ? (
                <>
                  <FaEyeSlash className="mr-2 text-lg" />
                  <span className="text-sm font-semibold">Hide Questions</span>
                </>
              ) : (
                <>
                  <FaEye className="mr-2 text-lg" />
                  <span className="text-sm font-semibold">Show Questions</span>
                </>
              )}
            </button>

            {showQuestions && (
              <div className="mt-4">
                {a.answers.map((q, i) => (
                  <div
                    key={i}
                    className="mb-4 p-4 bg-gray-100 rounded shadow"
                  >
                    <p className="font-medium text-lg">{q.question}</p>
                    <div className="ml-4 mt-2">
                      {q.options.map((option) => (
                        <p
                          key={option._id}
                          className={`${option.isCorrect ? "text-green-600" : ""
                            } ${option._id === q.selectedOption._id
                              ? "font-semibold underline"
                              : ""
                            }`}
                        >
                          - {option.text}
                        </p>
                      ))}
                    </div>
                    <p
                      className={`mt-2 ${q.isCorrect ? "text-green-600" : "text-red-600"
                        }`}
                    >
                      Your answer: {q.selectedOption.text} (
                      {q.isCorrect ? "Correct" : "Incorrect"})
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Quizzes;


// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { apiCallFetch } from "../api/apiHelper";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";
// import { Award, Book, Clock, Target } from "lucide-react";
// import { faL } from "@fortawesome/free-solid-svg-icons";



// const StatCard = ({ icon: Icon, title, value, subtitle }) => (
//   <div className="bg-white rounded-3xl p-3 flex items-center">
//     <div className="mr-3">
//       <div className="bg-purple-100 p-2 rounded-lg">
//         <Icon className="w-5 h-5 text-purple-600" />
//       </div>
//     </div>
//     <div>
//       <p className="text-sm text-gray-500">{title}</p>
//       <p className="text-xl font-bold">{value}</p>
//       {subtitle && <p className="text-xs text-gray-400">{subtitle}</p>}
//     </div>
//   </div>
// );


// const QuizCard = ({ quiz, onClick }) => (
//   <div
//     onClick={onClick}
//     className="flex items-center bg-white rounded-3xl px-3 py-2 hover:shadow-lg transition-all cursor-pointer border border-gray-300"
//   >
//     {console.log(quiz, onClick, "getiing")}
//     <div
//       className={`w-16 h-16 rounded-2xl flex items-center justify-center mr-2 ${quiz?.topics[0]?.name?.toLowerCase()?.includes("statistics")
//         ? "bg-purple-100"
//         : "bg-pink-100"
//         }`}
//     >
//       {quiz?.topics[0]?.name?.charAt(0)?.toUpperCase()}
//     </div>

//     <div className="flex-1">
//       <h2 className="text-l font-bold text-gray-900">{quiz.name}</h2>
//       <div className="flex text-sm items-center gap-2 text-gray-500">
//         <span>{quiz?.topics[0]?.name}</span>
//         <span>•</span>
//         <span>{quiz.totalQuestions} Questions</span>
//       </div>
//     </div>
//     <div className="text-purple-600">
//       <svg
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="currentColor"
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       >
//         <path d="M9 18l6-6-6-6" />
//       </svg>
//     </div>
//   </div>
// );

// const companyID = JSON.parse(localStorage.getItem("user"))?.company?._id;




// const Quizzes = () => {
//   const [quizzes, setQuizzes] = useState([]);
//   const [stats, setStats] = useState(null);
//   const navigate = useNavigate();
//   const [loading, isLoading] = useState(false);

//   const role = JSON.parse(localStorage.getItem("user"))?.role;

//   useEffect(() => {
//     const fetchData = async () => {
//       isLoading(true)
//       try {
//         // Fetch quizzes
//         // const quizzesData = await apiCallFetch(/quiz/all-quiz-by-company/${companyID}, "GET");
//         // const quizzesData = await apiCallFetch(/quiz/get-quiz-by-mentee, "GET");

//         const quizzesData = role === 'mentor'
//           ? await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET")
//           : await apiCallFetch("/quiz/get-quiz-by-mentee", "GET");

//         if (quizzesData.status) {
//           setQuizzes(quizzesData.data);
//           isLoading(false)
//         }
//         isLoading(false)


//         // Fetch stats
//         const statsData = await apiCallFetch("/quiz/stats/user", "GET");
//         if (statsData.success) {
//           setStats(statsData.data);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center min-h-screen bg-gray-100">
//         <div className="flex flex-col items-center">
//           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
//           <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
//         </div>
//       </div>
//     );
//   }


//   return (
//     <div
//       className="min-h-screen bg-olive-green p-5"
//       style={{ minHeight: `calc(100vh - 64px)` }}
//     >
//       <h2 className="text-xl font-bold mb-4 text-white">Quizzes</h2>
//       <div className="max-w-5xl mx-auto">

//         {console.log(stats, "================state")}
//         {stats && quizzes.length !== 0 ? (
//           <div className="mb-8">
//             <div className="mb-4 flex justify-between items-center">
//               <h2 className="text-xl font-bold mb-4 text-white">
//                 Your Progress
//               </h2>
//               <button
//                 onClick={() => navigate("/user-quiz-report")}
//                 className="text-white font-semibold hover:text-white"
//               >
//                 See All
//               </button>
//             </div>
//             <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
//               <StatCard
//                 icon={Target}
//                 title="Pass Rate"
//                 value={`${stats.passRate ? stats.passRate.toFixed(2) : 0}%`}
//               />
//               <StatCard
//                 icon={Award}
//                 title="Best Score"
//                 value={`${!stats.highestScore ? 0 : Math.round(stats.highestScore)}%`}
//               />
//               <StatCard
//                 icon={Book}
//                 title="Attempts"
//                 value={`${!stats.totalAttempts ? 0 : stats.totalAttempts}%`}
//               />
//               <StatCard
//                 icon={Clock}
//                 title="Avg. Time/Q"
//                 value={`${stats.timeAnalysis
//                   ? stats.timeAnalysis.avgTimePerQuestion.toFixed(2)
//                   : 0
//                   }s`}
//               />
//             </div>

//             <div className="bg-white p-4 rounded-3xl mb-8">
//               <h3 className="text-lg font-semibold mb-4">
//                 Progress Over Time
//               </h3>
//               <div className="h-48">
//                 <ResponsiveContainer width="100%" height="100%">
//                   <BarChart data={stats.progressOverTime}>
//                     <XAxis dataKey="_id" />
//                     <YAxis />
//                     <Tooltip />
//                     <Bar dataKey="avgScore" fill="#8b5cf6" />
//                   </BarChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>
//           </div>
//         ) :
//           <p className="text-white text-lg mt-1 text-center">No Quizzes Found</p>
//         }


//         {/* Recent Quizzes Section */}
//         {stats && quizzes.length !== 0 &&
//           <div className="mb-4 flex justify-between items-center">
//             <h2 className="text-xl font-bold text-white">Recent Quizzes</h2>

//             <button
//               onClick={() => navigate("/all-quizzes")}
//               className="text-white font-semibold hover:text-white"
//             >
//               See All
//             </button>
//           </div>
//         }

//         <div className="space-y-4">
//           {

//             role === 'mentor'

//               ?

//               quizzes.slice(0, 5).map((quiz) => (
//                 <QuizCard
//                   key={quiz._id}
//                   quiz={quiz}
//                   onClick={() => navigate(`/quiz/${quiz._id}`)}
//                 />
//               ))
//               :
//               quizzes.slice(0, 5).map((quiz) => (
//                 quiz.quizzes.map((q) => (
//                   <QuizCard
//                     key={quiz._id}
//                     quiz={q}
//                     onClick={() => navigate(`/quiz/${q._id}`)}
//                   />
//                 ))
//               ))

//           }
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Quizzes;