import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    AiOutlineClockCircle,
    AiOutlineArrowRight,
    AiOutlineDashboard,
    AiOutlineReload,
} from "react-icons/ai";
import { BsQuestionCircle, BsCheckCircle, BsTrophy } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import { FaRegFileAlt } from "react-icons/fa";
import { baseURL } from "../api/apiHelper";

const Quiz = () => {
    const [quiz, setQuiz] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [timer, setTimer] = useState(null);
    const [isStarted, setIsStarted] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [timeSpentPerQuestion, setTimeSpentPerQuestion] = useState({});
    const [quizResult, setQuizResult] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(
        JSON.parse(localStorage.getItem("user"))?.token
    );
    const quizId = location.pathname.split("/").pop();

    useEffect(() => {
        fetchQuiz();
    }, [quizId]);

    const fetchQuiz = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(baseURL + `/api/quiz/get/${quizId}`, {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            });
            const result = await response.json();
            if (result.status) {
                setQuiz(result.data);
                setTimer(result.data.timePerQuestion);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        let interval;
        if (isStarted && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        handleNextQuestion();
                        return quiz.timePerQuestion;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isStarted, timer]);

    const handleStartQuiz = () => {
        setIsStarted(true);
        setTimer(quiz.timePerQuestion);
    };

    const handleNextQuestion = () => {
        // Record time spent on current question
        const timeSpent = quiz.timePerQuestion - timer;
        setTimeSpentPerQuestion((prev) => ({
            ...prev,
            [quiz.questions[currentQuestion].question._id]: timeSpent,
        }));

        if (currentQuestion < quiz.questions.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
            setTimer(quiz.timePerQuestion);
        }
    };

    const handleSelectAnswer = (questionId, optionId) => {
        setSelectedAnswers((prev) => ({
            ...prev,
            [questionId]: optionId,
        }));
    };

    const handleSubmitQuiz = async () => {
        setIsSubmitting(true);
        const timeSpent = quiz.timePerQuestion - timer;
        setTimeSpentPerQuestion((prev) => ({
            ...prev,
            [quiz.questions[currentQuestion].question._id]: timeSpent,
        }));

        const answers = quiz.questions.map((q) => ({
            selectedOption: selectedAnswers[q.question._id],
            timeSpent: timeSpentPerQuestion[q.question._id] || 0,
        }));

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            quizId: quizId,
            answers: answers,
        });

        try {
            const response = await fetch(baseURL + "/api/quiz/attempt", {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            });
            console.log(response, "resulr=========================");

            const result = await response.json();
            if (result.status) {
                setQuizResult(result.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!quiz) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (quizResult) {
        return (
            <div
                className="min-h-screen bg-olive-green p-5 flex justify-center"
                style={{ minHeight: `calc(100vh - 64px)` }}
            >
                <div className="h-max mt-16 bg-white rounded-3xl shadow-lg w-full max-w-2xl p-6 text-center">
                    <div className="mb-6">
                        {quizResult.passed ? (
                            <div className="animate-bounce">
                                <BsTrophy className="text-yellow-400 text-6xl mx-auto mb-4" />
                                <h2 className="text-2xl font-bold text-green-600">
                                    Congratulations!
                                </h2>
                                <p className="text-gray-600 mt-2">You passed the quiz!</p>
                            </div>
                        ) : (
                            <div>
                                <BsQuestionCircle className="text-red-400 text-6xl mx-auto mb-4" />
                                <h2 className="text-2xl font-bold text-red-600">
                                    Quiz Not Passed
                                </h2>
                                <p className="text-gray-600 mt-2">
                                    Keep practicing and try again!
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="grid grid-cols-2 gap-4 mb-6">
                        <div className="bg-gray-50 p-4 rounded">
                            <p className="font-semibold">Score</p>
                            <p className="text-xl">
                                {quizResult.score}/{quizResult.totalPossibleScore}
                            </p>
                        </div>
                        <div className="bg-gray-50 p-4 rounded">
                            <p className="font-semibold">Percentage</p>
                            <p className="text-xl">
                                {quizResult.percentageScore.toFixed(2)}%
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center gap-4">
                        <button
                            onClick={() => navigate("/quizzes")}
                            className="bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-2 rounded-lg flex items-center gap-2"
                        >
                            <AiOutlineDashboard /> Go to Dashboard
                        </button>
                        {!quizResult.passed && (
                            <button
                                onClick={() => {
                                    setQuizResult(null);
                                    setCurrentQuestion(0);
                                    setSelectedAnswers({});
                                    setTimeSpentPerQuestion({});
                                    setIsStarted(false);
                                }}
                                className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg flex items-center gap-2"
                            >
                                <AiOutlineReload /> Retry Quiz
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    if (!isStarted) {
        return (
            <div
                className="min-h-screen bg-olive-green p-4 flex justify-center items-center overflow-auto"
                style={{ height: 'auto' }}
            >
                <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-6xl mx-auto bg-white rounded-3xl shadow-lg p-4 sm:p-6">

                    <h2 className="text-2xl sm:text-3xl font-bold flex items-center gap-2 mb-4">
                        <FaRegFileAlt className="text-[#24284c]" />
                        {quiz.name}
                    </h2>
                    <p className="text-sm sm:text-base text-gray-600 mb-4">{quiz.description}</p>

                    <div className="space-y-4">
                        <div className="flex items-center gap-3 bg-gray-50 p-4 rounded-lg">
                            <BsQuestionCircle className="text-[#24284c]" />
                            <div>
                                <p className="font-semibold text-sm">Total Questions</p>
                                <p className="text-sm">{quiz.totalQuestions}</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-3 bg-gray-50 p-4 rounded-lg">
                            <IoMdTime className="text-[#24284c]" />
                            <div>
                                <p className="font-semibold text-sm">Time per Question</p>
                                <p className="text-sm">{quiz.timePerQuestion} seconds</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-3 bg-gray-50 p-4 rounded-lg">
                            <BsCheckCircle className="text-[#24284c]" />
                            <div>
                                <p className="font-semibold text-sm">Total Marks</p>
                                <p className="text-sm">{quiz.questions.reduce((acc, q) => acc + q.marks, 0)}</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-3 bg-gray-50 p-4 rounded-lg">
                            <BsCheckCircle className="text-[#24284c]" />
                            <div>
                                <p className="font-semibold text-sm">Passing Score</p>
                                <p className="text-sm">{quiz.passingScore}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6">
                        <button
                            onClick={handleStartQuiz}
                            className="w-full bg-olive-green hover:bg-olive-green text-white py-3 sm:py-4 px-6 sm:px-8 rounded-3xl text-lg sm:text-xl flex items-center justify-center gap-4 transition-colors"
                        >
                            <AiOutlineClockCircle size={20} />
                            Start Quiz
                        </button>
                    </div>
                </div>
            </div>
        );
    }


    const currentQuestionData = quiz.questions[currentQuestion].question;

    return (
        <div className="bg-white flex justify-center items-center min-h-screen w-full">
            <div className="bg-white rounded-3xl shadow-lg w-full h-full max-w-none flex flex-col">
                <div className="p-6 border-b flex justify-between items-center">
                    <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold flex items-center gap-2">
                        <BsQuestionCircle className="text-indigo-600" />
                        Question {currentQuestion + 1} of {quiz.totalQuestions}
                    </h2>
                    <div className="flex items-center gap-2 text-indigo-600">
                        <IoMdTime size={30} />
                        {/* <span className="font-bold text-sm sm:text-base">{timer}s</span> */}
                        <span className="font-bold text-lg sm:text-2xl">{timer}s</span>

                    </div>
                </div>

                <div className="p-6 space-y-4 flex-1 overflow-y-auto flex flex-col justify-between">
                    <div className="text-lg sm:text-xl lg:text-2xl font-medium mb-4 flex-grow">
                        {currentQuestionData.question}
                    </div>
                    <div className="space-y-3 flex-grow">
                        {currentQuestionData.options.map((option) => (
                            <div
                                key={option._id}
                                onClick={() =>
                                    handleSelectAnswer(currentQuestionData._id, option._id)
                                }
                                className={`p-3 rounded-lg border cursor-pointer transition-all ${selectedAnswers[currentQuestionData._id] === option._id
                                    ? "border-indigo-600 bg-indigo-50"
                                    : "border-gray-200 hover:border-indigo-600"
                                    }`}
                            >
                                {option.text}
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-6 space-x-4">
                        {currentQuestion === quiz.questions.length - 1 ? (
                            <button
                                onClick={handleSubmitQuiz}
                                disabled={isSubmitting}
                                className="flex items-center gap-2 px-6 py-2 rounded-lg bg-green-600 hover:bg-green-700 text-white text-sm sm:text-base"
                            >
                                {isSubmitting ? "Submitting..." : "Finish Quiz"}{" "}
                                <BsCheckCircle size={16} />
                            </button>
                        ) : (
                            <button
                                onClick={handleNextQuestion}
                                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-indigo-600 hover:bg-indigo-700 text-white text-sm sm:text-base"
                            >
                                Next <AiOutlineArrowRight size={16} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Quiz;

