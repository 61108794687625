import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaBuilding, FaCity, FaEnvelope, FaPhone, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import { apiCallFetch, baseURL } from '../api/apiHelper';
import '../styles/give.css';

const GlobalReferrals = () => {  // Add isCompany as a prop
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenPhone, setModalOpenPhone] = useState(false);
    const [modalOpenWhatsapp, setModalOpenWhatsapp] = useState(false);
    const [categories, setCategories] = useState([]);
    const [err, setErr] = useState('');
    const [currentReferral, setCurrentReferral] = useState(null);
    const [currentPhone, setCurrentPhone] = useState(null);
    const [currentWhatsapp, setCurrentWhatsapp] = useState(null);
    const [formData, setFormData] = useState({ name: '', category: '', department: '', company: '', city: '', state: '', phone: '', email: '', });

    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [referrals, setReferrals] = useState([]);
    const [interestedCards, setInterestedCards] = useState({});
    const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0 });
    const [hasMore, setHasMore] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingMap, setLoadingMap] = useState(false);

    const ITEMS_PER_PAGE = 10;
    const observer = useRef();
    const lastReferralElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPagination(prev => ({ ...prev, page: prev.page + 1 }));
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const LoadingSpinner = ({ className }) => (
        <div className={`flex justify-center ${className}`}>
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    const fetchCategories = async () => {
        try {
            const response = await apiCallFetch('/categories/get', 'GET');
            setCategories(response.data);

        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        // setPagination(prev => ({ ...prev, page: 1 }));
        setPagination({ page: 1, limit: 10, total: 0 });
        setReferrals([]);
        setHasMore(true);
        fetchReferrals(1, false);
    }, [searchTerm, selectedCategory]);

    useEffect(() => {
        fetchCategories();
        fetchReferrals();
    }, []);

    useEffect(() => {
        if (pagination.page > 1) {
            fetchReferrals(pagination.page, true);
        }
    }, [pagination.page]);

    const fetchReferrals = async (pageNum = 1, shouldAppend = false) => {
        if (!hasMore && shouldAppend) return;

        if (shouldAppend) {
            setLoading(true);
        } else {
            setInitialLoading(true);
        }

        try {
            const searchParam = searchTerm ? `search=${searchTerm}` : '';
            const categoryParam = selectedCategory ? `category=${selectedCategory}` : '';
            const pageParam = `page=${pageNum}`;
            const query = [searchParam, categoryParam, pageParam].filter(Boolean).join('&');

            const endpoint = JSON.parse(localStorage.getItem('company')) && JSON.parse(localStorage.getItem('company')).role === 'company'
                ? `/referrals/company-get-by-company?${query}`
                : `/referrals/get-by-company?${query}`;

            const response = await apiCallFetch(endpoint, 'GET');
            const newReferrals = response.data || [];

            console.log(newReferrals, "new loggggggggggggggg");

            // Clear the error if we get valid referrals
            if (newReferrals.length > 0) {
                setErr(''); // Clear error when referrals are found
            } else if (pageNum === 1) {
                setErr('No referrals found for this company.');
            }

            if (shouldAppend) {
                setReferrals(prev => [...prev, ...newReferrals]);
            } else {
                setReferrals(newReferrals);
            }

            setPagination(prev => ({
                ...prev,
                limit: response.limit,
                total: response.total
            }));

            const totalPages = Math.ceil(response.total / ITEMS_PER_PAGE);
            const hasMoreData = newReferrals.length > 0;

            // Determine if there are more pages to load
            setHasMore(pageNum < totalPages && hasMoreData);

        } catch (error) {
            // Only set error if referrals are not already displayed
            if (!shouldAppend && referrals.length === 0) {
                setErr(error?.response?.data?.message || 'Error fetching referrals.');
            }
            console.error('Error fetching referrals:', error);
        } finally {
            if (shouldAppend) {
                setLoading(false);
                setLoadingMap(false);
            } else {
                setInitialLoading(false);
            }
        }
    };

    const handleConnect = (referral) => {
        setCurrentReferral(referral);
        setModalOpen(true); // Open the call modal
    };

    // const handleConfirmConnect = async () => {
    //     const referralId = currentReferral._id;

    //     // Set loading state for the specific referral
    //     setLoadingMap(true);

    //     try {
    //         const payload = {
    //             targetUID: currentReferral?.user?._id, // Replace with actual UID
    //             targetRefID: currentReferral?._id, // Replace with actual RefID
    //         };

    //         const response = await apiCallFetch('/connection/send-connect', 'POST', payload, {
    //             'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
    //         });

    //         if (response.status) {
    //             fetchReferrals();
    //             // Reset loading state for the specific referral
    //             // setLoadingMap(false);
    //             // console.log("Connection confirmed:", response.data);
    //         } else {
    //             // Reset loading state in case of failure
    //             setLoadingMap(false);
    //             console.error("Failed to confirm connection:", response.message);
    //         }
    //     } catch (err) {
    //         // Reset loading state in case of error
    //         setLoadingMap(false);
    //         console.error("Error confirming connection:", err);
    //     } finally {
    //         setLoadingMap(false); // Reset loading state
    //         setModalOpen(false); // Close the modal
    //     }
    // };


    const handleConfirmConnect = async () => {
        const referralId = currentReferral._id;
        setLoadingMap(true);

        try {
            const payload = {
                targetUID: currentReferral?.user?._id,
                targetRefID: currentReferral?._id,
            };

            const response = await apiCallFetch('/connection/send-connect', 'POST', payload, {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
            });

            if (response.status) {
                // Update the referrals state locally instead of fetching
                setReferrals(prevReferrals => {
                    return prevReferrals.map(referral => {
                        if (referral._id === currentReferral._id) {
                            // Create a new connection request object
                            const newConnectionRequest = {
                                requesterID: user._id,  // Current user's ID
                                requestStatuses: [{
                                    requester: {
                                        _id: user._id  // Current user's ID
                                    }
                                }]
                            };

                            // Add the new connection request to the existing ones
                            return {
                                ...referral,
                                connectionRequests: [
                                    ...(referral.connectionRequests || []),
                                    newConnectionRequest
                                ]
                            };
                        }
                        return referral;
                    });
                });
            } else {
                console.error("Failed to confirm connection:", response.message);
            }
        } catch (err) {
            console.error("Error confirming connection:", err);
        } finally {
            setLoadingMap(false);
            setModalOpen(false);
        }
    };
    const handlePhoneConnect = (referral) => {
        setCurrentPhone(referral);
        setModalOpenPhone(true);
    };

    const handleConfirmPhoneConnect = () => {
        const receiverPhone = currentPhone?.user?.profile?.phone; // Receiver's phone number
        console.log(receiverPhone, "======123========");
        // Construct the phone dialer link
        const phoneUrl = `tel:${receiverPhone}`;
        // Open the phone dialer
        window.open(phoneUrl, "_self");
        // Close the modal
        setModalOpenPhone(false);
    };

    const handleWhatsappConnect = (referral) => {
        setCurrentWhatsapp(referral);
        setModalOpenWhatsapp(true);
    };

    const handleConfirmWhatsappConnect = () => {
        const receiverName = currentWhatsapp?.user?.profile?.name; // Receiver's name
        const receiverNumber = currentWhatsapp?.user?.profile?.whatsapp; // Receiver's WhatsApp number
        const referralName = currentWhatsapp?.name || "Referral Name"; // Referral name
        // Construct the WhatsApp message
        const message = `Hi ${receiverName},\n\nI want to connect with your contact ${referralName}, please share your 1-2-1 / call availability.`;
        // Generate the WhatsApp URL
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${receiverNumber}&text=${encodeURIComponent(message)}`;
        // Open WhatsApp link in a new tab
        window.open(whatsappUrl, "_blank");
        // Close the modal
        setModalOpenWhatsapp(false);
    };

    const user = JSON.parse(localStorage.getItem('user')) || {};
    const company = JSON.parse(localStorage.getItem('company')) || {};

    function checkIfRequesterMatches(connectionRequest, currentLoginId) {
        console.log(connectionRequest.connectionRequests?.requestStatuses?.some(status => status?.requester?._id === currentLoginId), "miiii")
        return connectionRequest.connectionRequests?.requestStatuses?.some(status => status?.requester?._id === currentLoginId);
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4 mt-2">{user.role == 'admin' ? null : user.company?.name || company?.name} - All Gives ({pagination.total})</h1>
            {/* <h1 className="text-2xl font-bold mb-4">All Gives ({pagination.total})</h1> */}


            <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setErr('');
                    }}
                    className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3 input-bg rounded"
                />
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="border p-2 mb-2 md:mb-0 rounded w-full md:w-1/3"
                >
                    <option value="">All Categories</option>
                    {categories.map((category, i) => (
                        <option value={category._id} key={i}>{category.name}</option>
                    ))}
                </select>
            </div>

            {initialLoading ? (
                <LoadingSpinner className="min-h-[200px] items-center" />
            ) : (
                <>
                    {err && <p>{err}</p>}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {/* {console.log(referrals, "okokookko")} */}
                        {referrals.map((referral, i) => {
                            const isRequesterMatch = checkIfRequesterMatches(referral, user._id);

                            const isRequesterIdExists = referral?.connectionRequests?.some(
                                (request) => request.requesterID === user._id);


                            return (
                                <div
                                    key={referral._id}
                                    ref={i === referrals.length - 1 ? lastReferralElementRef : null}
                                    className="bg-graylight p-4 rounded-xl flex flex-col relative"
                                >
                                    <h2 className="font-bold text-base text-gray-800 mb-4 lh-1 capitalize">
                                        {referral.name}
                                        <span className="font-normal fs-15 text-gray-600"> ({referral?.category?.name})</span>
                                    </h2>
                                    <div className="grid grid-cols-2 gap-4 text-xs text-gray-600">
                                        <div className="flex items-center space-x-1">
                                            <FaBuilding className="text-[#24284c] fs-16 mr-1 gives-icon" />
                                            <span className="fs-14">{referral.company}</span>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <FaCity className="text-[#24284c] fs-16 mr-1 gives-icon" />
                                            <span className="fs-14">{referral.city}, {referral.state.split(' - ')[1]}</span>
                                        </div>
                                        <div className="grid grid-cols-1 gap-4 text-xs text-gray-600">
                                            <div className="flex items-center space-x-1">
                                                <FaEnvelope className="text-[#24284c] fs-16 mr-1 gives-icon" />
                                                <span className="fs-14">
                                                    {interestedCards[referral._id] ? referral.email : '*********@****.com'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <FaBuilding className="text-[#24284c] fs-16 mr-1 gives-icon" />
                                            <span className="fs-14">{referral.department}</span>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-4 mt-4 text-xs text-gray-600">
                                        <div className="flex items-center space-x-1">
                                            <FaPhone className="text-[#24284c] fs-15 mr-1 gives-icon" />
                                            <span className="fs-14">
                                                {interestedCards[referral._id] ? referral.phone : '**** ****'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-end justify-between mt-4 pt-4 border-t border-gray-200">
                                        {/* {console.log(user.hasAccess, "XXXXXX")} */}
                                        {

                                            <div className='w-full'>
                                                {

                                                    company.role == 'company' ? <>
                                                        <div className="flex items-center space-x-2">
                                                            <img
                                                                src={referral.user?.profile?.photo ? baseURL + referral.user?.profile?.photo : 'user-placeholder.jpeg'}
                                                                alt="User Avatar"
                                                                className="w-10 h-10 rounded-full object-cover flex-shrink-0"
                                                            />
                                                            <p className="text-sm text-gray-600 font-bold whitespace-nowrap">
                                                                {referral.user?.profile?.name || 'Unknown User'}
                                                            </p>
                                                        </div>
                                                    </> :

                                                        (isRequesterIdExists || user._id === referral?.user?._id) || !user.hasAccess.connect
                                                            ? (
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex items-center space-x-2">
                                                                        <img
                                                                            src={referral.user?.profile?.photo ? baseURL + referral.user?.profile?.photo : 'user-placeholder.jpeg'}
                                                                            alt="User Avatar"
                                                                            className="w-10 h-10 rounded-full object-cover flex-shrink-0"
                                                                        />
                                                                        <p className="text-sm text-gray-600 font-bold whitespace-nowrap">
                                                                            {referral.user?.profile?.name || 'Unknown User'}
                                                                        </p>
                                                                    </div>

                                                                    {user._id !== referral?.user?._id && ( // Hide buttons if user._id matches referral.user._id
                                                                        <div className="flex items-center space-x-2">
                                                                            <button
                                                                                onClick={() => handlePhoneConnect(referral)}
                                                                                className="text-white bg-olive-green px-3 py-2 rounded flex items-center space-x-1 transition-colors"
                                                                                disabled={loadingMap[referral._id]}
                                                                            >
                                                                                <FaPhoneAlt className="text-lg" />
                                                                                <span>Call</span>
                                                                            </button>

                                                                            <button
                                                                                onClick={() => handleWhatsappConnect(referral)}
                                                                                className="text-white bg-green-600 px-3 py-2 rounded flex items-center space-x-1 transition-colors"
                                                                                disabled={loadingMap[referral._id]}
                                                                            >
                                                                                <FaWhatsapp className="text-xl" />
                                                                                <span>Chat</span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )

                                                            : (  // Show Connect button only if the above condition is not met
                                                                <div className='flex'>
                                                                    <button
                                                                        // onClick={() => handleConfirmConnect(referral)}
                                                                        onClick={() => handleConnect(referral)}

                                                                        className="bg-olive-green  flex items-center text-white px-2 py-2 rounded connect-btn"
                                                                        disabled={loadingMap[referral._id]} // Disable button when loading
                                                                    >
                                                                        <FaPhoneAlt className="text-xl mr-2" />
                                                                        I Want To Connect
                                                                    </button>
                                                                    {/* Phone Button */}


                                                                </div>
                                                            )
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    {loading && <LoadingSpinner />}

                    {modalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white m-5 p-4 rounded-lg max-w-md w-full">
                                <h3 className="text-xl font-bold mb-2">Confirm Connection</h3>
                                <p>Are you sure you want to connect with this give?</p>
                                <div className="mt-4 flex justify-end space-x-2">
                                    <button
                                        onClick={() => setModalOpen(false)}
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                    >
                                        Cancel
                                    </button>
                                    <div >
                                    </div>
                                    <button
                                        onClick={handleConfirmConnect}
                                        className="bg-olive-green text-white px-4 py-2 rounded"
                                    >
                                        {loadingMap ? 'Connecting....' : 'Yes'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* WhatsApp Modal */}
                    {modalOpenWhatsapp && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white m-5 p-4 rounded-lg max-w-md w-full">
                                <h3 className="text-xl font-bold mb-4">Confirm WhatsApp Connection</h3>
                                <p>Are you sure you want to connect via WhatsApp?</p>
                                <div className="mt-4 flex justify-end space-x-4">
                                    <button
                                        onClick={() => setModalOpenWhatsapp(false)}
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleConfirmWhatsappConnect}
                                        className="bg-olive-green text-white px-4 py-2 rounded"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {modalOpenPhone && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white m-5 p-4 rounded-lg max-w-md w-full">
                                <h3 className="text-xl font-bold mb-4">Confirm phone call</h3>
                                <p>Are you sure you want to call ?</p>
                                <div className="mt-4 flex justify-end space-x-4">
                                    <button
                                        onClick={() => setModalOpenPhone(false)}
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleConfirmPhoneConnect}
                                        className="bg-olive-green text-white px-4 py-2 rounded"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </>
            )
            }
        </div>
    );
};

export default GlobalReferrals;
