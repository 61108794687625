// import React, { useEffect, useState } from "react";
// import { apiCallFetch, baseURL } from "../api/apiHelper";

// function TopContributors() {
//     const [topContributors, setTopContributors] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchTopContributors = async () => {
//             try {
//                 const response = await apiCallFetch("/users/get-dashboard", "GET");
//                 if (response.status) {
//                     const { topContributors } = response.data;
//                     setTopContributors(topContributors);
//                 } else {
//                     setError(response.message);
//                 }
//             } catch (err) {
//                 console.error("Failed to fetch top contributors", err);
//                 setError("Failed to load data. Please try again later.");
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchTopContributors();
//     }, []);

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <p className="text-gray-600">Loading...</p>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <p className="text-red-500">{error}</p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-olive-green bg-cover bg-no-repeat p-4">
//             {/* Header */}
//             <div className="flex items-center justify-center my-6">
//                 <h1 className="text-white text-3xl font-semibold">Top Contributors</h1>
//             </div>

//             {/* Contributors List */}
//             <div className="flex justify-center items-center">
//                 <div className="w-full max-w-4xl space-y-4">
//                     {topContributors.map((contributor, index) => (
//                         <div
//                             key={contributor.id}
//                             className="flex items-center space-x-4 p-4 rounded-3xl bg-gray-50 shadow-md"
//                         >
//                             {/* Rank Number */}
//                             <div className="w-8 h-8 flex items-center justify-center text-gray-400">
//                                 {index + 1}
//                             </div>

//                             {/* Contributor Image */}
//                             <div className="w-12 h-12">
//                                 <img
//                                     // src={contributor.image || "/user-placeholder.jpeg"}
//                                     src={contributor.photo ? `${baseURL}${contributor.photo}` : "/user-placeholder.jpeg"}
//                                     alt={contributor.name}
//                                     className="w-full h-full rounded-full"
//                                 />
//                             </div>

//                             {/* Contributor Details */}
//                             <div className="flex-1">
//                                 <p className="font-semibold text-gray-800">{contributor.name}</p>
//                                 <p className="text-gray-500 text-sm">{contributor.referralCount} Gives</p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default TopContributors;


import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCallFetch, baseURL } from "../api/apiHelper";

function TopContributors() {
    const { state } = useLocation();
    const selectedView = state?.selectedView || "allTime"; // Default to "allTime" if state is undefined
    const [topContributors, setTopContributors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTopContributors = async () => {
            setLoading(true);
            setError(null);
            try {
                const endpoint =
                    selectedView === "lastWeek"
                        ? "/users/get-lastweek-dashboard"
                        : "/users/get-dashboard";

                const response = await apiCallFetch(endpoint, "GET");
                console.log("Fetched Data:", response); // Debugging log

                if (response.status) {
                    // Access the topContributors from response.data
                    const contributors =
                        selectedView === "lastWeek"
                            ? response.data.topContributors // Weekly response
                            : response.data.topContributors; // All-time response

                    if (contributors && Array.isArray(contributors)) {
                        setTopContributors(contributors);
                    } else {
                        setError("No contributors found.");
                    }
                } else {
                    setError(response.message || "Error fetching contributors.");
                }
            } catch (err) {
                console.error("Failed to fetch top contributors:", err);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchTopContributors();
    }, [selectedView]);


    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-olive-green bg-cover bg-no-repeat p-4">
            <div className="flex items-center justify-center my-6">
                <button
                    type="button"
                    className="text-white text-3xl mr-2"
                    onClick={() => navigate(-1)} // Navigate back to the previous page
                >
                    ←
                </button>
                <h1 className="text-white text-3xl font-semibold">All Contributors</h1>
            </div>

            <div className="flex justify-center items-center">
                <div className="w-full max-w-4xl space-y-4">
                    {topContributors.map((contributor, index) => (
                        <div
                            key={contributor.id}
                            className="flex items-center space-x-4 p-4 rounded-3xl bg-gray-50 shadow-md"
                        >
                            <div className="w-8 h-8 flex items-center justify-center text-gray-400">
                                {index + 1}
                            </div>
                            <div className="w-12 h-12">
                                <img
                                    src={contributor.photo ? `${baseURL}${contributor.photo}` : "/user-placeholder.jpeg"}
                                    alt={contributor.name}
                                    className="w-full h-full rounded-full"
                                />
                            </div>
                            <div className="flex-1">
                                <p className="font-semibold text-gray-800">{contributor.name}</p>
                                <p className="text-gray-500 text-sm">{contributor.referralCount} Gives</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopContributors;
