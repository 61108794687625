
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Attempts = () => {
    const location = useLocation();
    const { totalAttempts, assignedQuizzes } = location.state.data || {};

    console.log(location.state, "*************************-------------");

    const navigate = useNavigate();

    return (
        <div className="p-6 max-w-screen-lg mx-auto">
            {/* Header with back button */}
            <div className="flex items-center mb-6">
                    <button
                        type="button"
                        className="text-gray-600 text-3xl mr-4"
                        onClick={() => navigate(-1)} // Navigate back to the previous page
                    >
                        ←
                    </button>
                    <h2 className="text-3xl font-semibold text-gray-900">Total Attempts of {location?.state?.data?.profile.name}</h2>
            </div>

            {/* Table for Total Attempts */}
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                <table className="min-w-full table-auto border-collapse">
                    <thead>
                        <tr className="bg-gray-100 text-gray-700">
                            <th className="border-b px-6 py-3 text-left">Quiz Name</th>
                            <th className="border-b px-6 py-3 text-left">Score</th>
                            <th className="border-b px-6 py-3 text-left">Percentage Score</th>
                            <th className="border-b px-6 py-3 text-left">Status</th>
                            <th className="border-b px-6 py-3 text-left">Start Time</th>
                            <th className="border-b px-6 py-3 text-left">End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalAttempts && totalAttempts.length > 0 ? (
                            totalAttempts.map((attempt) => (
                                <tr key={attempt._id} className="hover:bg-gray-50">
                                    {console.log(attempt, "jhjbhjbhjbhbjhj")}
                                    <td className="border-b px-6 py-4">{attempt.quiz.name}</td>
                                    <td className="border-b px-6 py-4">{attempt.score}</td>
                                    <td className="border-b px-6 py-4">{attempt.percentageScore}%</td>
                                    <td className="border-b px-6 py-4">{attempt.status}</td>
                                    <td className="border-b px-6 py-4">{new Date(attempt.startTime).toLocaleString()}</td>
                                    <td className="border-b px-6 py-4">{new Date(attempt.updatedAt).toLocaleString()}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="border-b px-6 py-4 text-center text-gray-500">No attempts available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default Attempts;
