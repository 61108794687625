import React, { useEffect, useState } from "react";
import { apiCallFetch } from "../api/apiHelper";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

function ViewMentees() {
    const [mentees, setMentees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [quizzes, setQuizzes] = useState([]);
    const [quizeIDs, setQuizeIDs] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMentee, setSelectedMentee] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const mentor = JSON.parse(localStorage.getItem("user"));
    const mentorId = mentor ? mentor._id : null;

    useEffect(() => {
        const fetchMentees = async () => {
            try {
                const response = await apiCallFetch(`/users/get-mentee/${mentorId}`, "GET");
                if (response.success) {
                    setMentees(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        const companyID = JSON.parse(localStorage.getItem("user"))?.company._id;

        const fetchQuizzes = async () => {
            try {
                const response = await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET");
                if (response.status) {
                    setQuizzes(response.data);
                }
            } catch (err) {
                setError("Failed to load quizzes. Please try again later.");
            }
        };

        fetchMentees();
        fetchQuizzes();
    }, [mentorId]);

    const handleChange = (selectedOptions, menteeID) => {
        const selectedQuizIds = selectedOptions.map(option => option.value);
        setQuizeIDs(prevState => ({
            ...prevState,
            [menteeID]: selectedQuizIds,
        }));
    };

    const assignQuiz = async (menteeID) => {
        setLoading(true);
        try {
            const response = await apiCallFetch(`/users/assign-quiz`, "POST", {
                mentorID: mentorId,
                menteeID,
                quizIDs: quizeIDs[menteeID] || [],
            });

            if (response.success) {
                setSuccessMessage("Quiz assigned successfully!");
                setMentees(prevMentees =>
                    prevMentees.map(mentee =>
                        mentee._id === menteeID
                            ? {
                                ...mentee,
                                assignedQuizzes: [
                                    ...mentee.assignedQuizzes,
                                    ...quizzes.filter(quiz =>
                                        quizeIDs[menteeID]?.includes(quiz._id)
                                    ),
                                ],
                            }
                            : mentee
                    )
                );
                setQuizzes(prevQuizzes =>
                    prevQuizzes.filter(quiz => !quizeIDs[menteeID].includes(quiz._id))
                );
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError("Failed to assign quiz. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const openModal = (mentee) => {
        setSelectedMentee(mentee);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedMentee(null);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    const handleAttempts = (data) => {
        navigate("/attempts", { state: { data: data } });
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4 sm:p-6 lg:p-8">
            <div className="w-full max-w-6xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-3xl sm:text-4xl font-semibold text-gray-800">Mentees</h1>
                </div>

                <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-2">
                    {mentees.map((mentee) => (
                        <div
                            key={mentee._id}
                            className="bg-white p-4 sm:p-6 rounded-xl shadow-md hover:shadow-lg transition-all"
                        >
                            <div className="flex items-center space-x-4">
                                <img
                                    src={mentee.image || "/user-placeholder.jpeg"}
                                    alt={mentee.profile.name}
                                    className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-2 border-gray-300"
                                />
                                <div>
                                    <p className="text-lg sm:text-xl font-semibold text-gray-800">
                                        {mentee.profile.name}
                                    </p>
                                    <p className="text-sm mb-1 text-gray-600">{mentee.email}</p>
                                    <div className=" flex justify-between items-center">
                                        <button
                                            onClick={() => handleAttempts(mentee)}
                                            className="py-1 px-2 mr-2 bg-olive-green text-white rounded-full shadow text-xs"
                                        >
                                            Show Attempts
                                        </button>
                                        <button
                                            onClick={() => openModal(mentee)}
                                            className="py-1 px-2 bg-olive-green text-white rounded-full shadow text-xs"
                                        >
                                            Assign Quiz
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <div className="mt-4">
                                <p className="font-semibold text-gray-700">Assigned Quizzes:</p>
                                {mentee.assignedQuizzes.length > 0 ? (
                                    <ul className="mt-2 space-y-2">
                                        {mentee.assignedQuizzes.map((quiz) => (
                                            <li
                                                key={quiz._id}
                                                className="bg-gray-100 p-2 rounded-lg shadow-sm flex justify-between items-center"
                                            >
                                                <div>
                                                    <p className="text-sm font-semibold text-gray-800">
                                                        {quiz.name}
                                                    </p>
                                                    <p className="text-xs text-gray-500">{quiz.description}</p>
                                                </div>
                                                <div className="text-right">
                                                    <p className="text-xs text-gray-500"><strong>Questions: </strong>{quiz.totalQuestions}</p>
                                                    <p className="text-xs text-gray-500"><strong>Passing Marks: </strong> {quiz.passingScore}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500 text-sm">No quizzes assigned.</p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {isModalOpen && selectedMentee && (
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center p-4">
                        <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md">
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
                                Assign Quizzes to {selectedMentee.profile.name}
                            </h2>
                            <Select
                                isMulti
                                name="quizzes"
                                options={quizzes
                                    .filter(
                                        (quiz) =>
                                            !selectedMentee.assignedQuizzes.some(
                                                (assignedQuiz) => assignedQuiz._id === quiz._id
                                            )
                                    )
                                    .map((quiz) => ({
                                        value: quiz._id,
                                        label: quiz.name,
                                    }))}
                                value={quizzes
                                    .filter((quiz) =>
                                        (quizeIDs[selectedMentee._id] || []).includes(quiz._id)
                                    )
                                    .map((quiz) => ({
                                        value: quiz._id,
                                        label: quiz.name,
                                    }))}
                                onChange={(selectedOptions) =>
                                    handleChange(selectedOptions, selectedMentee._id)
                                }
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Select quizzes"
                            />
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={() => closeModal()}
                                    className="px-4 py-2 bg-gray-500 text-white rounded-full"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        assignQuiz(selectedMentee._id);
                                        closeModal();
                                    }}
                                    className="px-4 py-2 bg-blue-500 text-white rounded-full"
                                >
                                    Assign
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ViewMentees;
