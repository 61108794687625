import React, { useState, useEffect } from 'react';
import { apiCallFetch, baseURL } from '../api/apiHelper';
import '../styles/give.css';


const UpdateProfile = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [company, setCompany] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [userToken, setUserToken] = useState(JSON.parse(localStorage.getItem('user')).token); // Replace with actual token logic
    const [err, setErr] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // For showing success message
    const [loading, setLoading] = useState(true);
    const [isProfileLoading, setIsProfileLoading] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            setIsProfileLoading(true);
            try {
                const profileData = await apiCallFetch('users/get-profile', 'GET', {}, {
                    'Authorization': `Bearer ${userToken}`,
                });

                if (profileData.status) {
                    setFullName(profileData.data?.profile?.name);
                    setEmail(profileData.data?.email);
                    setMobile(profileData.data?.profile?.phone);
                    setWhatsapp(profileData.data?.profile?.whatsapp);
                    setCompany(profileData.data?.company?.name);

                    // Construct the full URL for the profile image
                    const profilePhoto = profileData.data?.profile?.photo
                        ? `${baseURL}${profileData.data.profile.photo}`
                        : '/user-placeholder.jpeg';

                    setProfileImage(profilePhoto);
                    console.log("Profile Photo URL:", profileData.data.profile.photo);
                } else {
                    setErr(profileData.message);
                }
            } catch (error) {
                setErr(error?.response?.data?.message || 'Error fetching profile');
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchProfile();
    }, [userToken]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setProfileImage(URL.createObjectURL(file)); // Sets image preview
        }
    };



    const handleUpdateClick = async () => {
        setLoading(true);
        setErr('');  // Clear previous errors
        setSuccessMessage('');  // Clear previous success messages

        try {
            const formData = new FormData();
            formData.append('name', fullName);
            formData.append('phone', mobile);
            formData.append('whatsapp', whatsapp);
            formData.append('email', email);
            if (selectedFile) {
                formData.append('photo', selectedFile, selectedFile.name);
            }

            const response = await apiCallFetch('users/update-profile', 'PUT', formData, {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'multipart/form-data' // Ensure this is set
            });

            if (response.status) {
                setSuccessMessage(response.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 2000);
            } else {
                setErr(response.message);
            }
        } catch (error) {
            setErr('Error updating profile: ' + (error.response ? error.response.data.message : error.message));
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }


    return (
        <div className="flex justify-center items-center bg-gray-100 p-4 mt-2">
            <div className="max-w-full w-full p-6 bg-white shadow-md rounded-lg">
                <h1 className="text-xl font-bold mb-4">Update Profile</h1>

                {err && <p className="text-red-500">{err}</p>}  {/* Show error message */}

                <div className="space-y-4">
                    <div className="flex items-center mb-4">
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="w-20 h-20 rounded-full border-2 border-gray-300"
                        />
                        <label className="ml-4">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="hidden"
                            />
                            <span className="bg-blue-500 text-white py-1 px-2 rounded cursor-pointer">Edit</span>
                        </label>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1">Full Name</label>
                            <input
                                type="text"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                className="border p-2 w-full input-bg rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-1">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border p-2 w-full input-bg rounded"
                                required
                                disabled
                            />
                        </div>
                        <div>
                            <label className="block mb-1 rounded">Mobile</label>
                            <input
                                type="tel"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                className="border p-2 w-full input-bg rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-1 rounded">WhatsApp</label>
                            <input
                                type="tel"
                                value={whatsapp}
                                onChange={(e) => setWhatsapp(e.target.value)}
                                className="border p-2 w-full input-bg rounded"
                            // required
                            />
                        </div>
                        <div>
                            <label className="block mb-1">Chapter</label>
                            <input
                                type="text"
                                value={company}
                                className="border p-2 w-full input-bg rounded"
                                disabled
                            />
                        </div>
                    </div>

                    <button
                        type="button"
                        onClick={handleUpdateClick}
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update Profile'}
                    </button>

                    {successMessage && (
                        <p className="text-500 mt-4">{successMessage}</p>  // Success message below button
                    )}
                </div>
            </div>
        </div>
    );
};


export default UpdateProfile;
