import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';

const QuestionMaster = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingTopics, setLoadingTopics] = useState(true);
    const [error, setError] = useState(null);
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestions = async () => {
            setLoading(true);
            try {
                const result = await apiCallFetch('/questions/get', 'GET');
                setQuestions(result.data.questions);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, []);

    useEffect(() => {
        const fetchTopics = async () => {
            setLoadingTopics(true);
            try {
                const result = await apiCallFetch('/topic/get', 'GET');
                if (result.status) {
                    setTopics(result.data);
                }
            } catch (error) {
                console.error("Error fetching topics:", error);
            } finally {
                setLoadingTopics(false);
            }
        };

        fetchTopics();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this question?")) {
            try {
                await apiCallFetch(`/questions/delete/${id}`, 'PUT');
                setQuestions(questions.filter(question => question._id !== id));
            } catch (err) {
                console.error("Error deleting question:", err);
                setError("Failed to delete the question.");
            }
        }
    };

    const handleEdit = (question) => {
        navigate('/add-question', { state: { question } });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }


    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    const filteredQuestions = selectedTopic
        ? questions.filter(question => question.topic._id === selectedTopic)
        : questions;

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">Question Master</h1>
                <div className="flex gap-5 items-center">
                    <select
                        value={selectedTopic}
                        onChange={(e) => setSelectedTopic(e.target.value)}
                        className="border p-2 rounded-md h-10"
                    >
                        <option value="">Select a topic</option>
                        {topics.map((topic) => (
                            <option key={topic._id} value={topic._id}>
                                {topic.name}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={() => navigate('/add-question')} // Navigate to the desired route
                        className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300 flex items-center justify-center ml-auto h-10"
                        title="Add Question"
                    >
                        <FaPlus className="mr-2 text-lg" />
                        <span className="text-sm font-semibold">Add Question</span>
                    </button>

                </div>
            </div>

            {filteredQuestions.length > 0 ? (
                <ul className="space-y-6">
                    {filteredQuestions.map((question, index) => (
                        <li key={question._id} className="bg-white shadow-md rounded-lg p-6 relative">
                            <div className="absolute top-2 right-2 flex space-x-2">
                                <button
                                    onClick={() => handleEdit(question)}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    onClick={() => handleDelete(question._id)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <FaTrash />
                                </button>
                            </div>
                            <h3 className="text-xl font-semibold mb-2">
                                {index + 1}. {question.question}
                            </h3>
                            <p className="text-gray-700 mb-2">Topic: <span className="font-medium">{question.topic.name}</span></p>
                            <p className="text-gray-600 mb-2">Explanation: {question.explanation}</p>
                            <p className="text-gray-500 mb-2">Difficulty: <span className="font-medium">{question.difficulty}</span></p>
                            <h4 className="font-semibold mt-4">Options:</h4>
                            <ul className="mt-2 grid grid-cols-2 gap-2">
                                {question.options.map(option => (
                                    <li key={option._id} className={`p-2 rounded-md ${option.isCorrect ? 'bg-green-100' : 'bg-red-100'} transition duration-300`}>
                                        <span className={option.isCorrect ? 'text-green-700' : 'text-red-700'}>
                                            {option.text} {option.isCorrect ? "(Correct)" : ""}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-center text-gray-500">No questions available.</p>
            )}
        </div>
    );
};

export default QuestionMaster;
