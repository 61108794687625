// import React, { useEffect, useState } from 'react';
// import { apiCallFetch } from '../api/apiHelper'; // Adjust path if needed
// import { useNavigate } from 'react-router-dom';
// import { FaEye, FaEyeSlash, FaUserMinus, FaUserPlus } from 'react-icons/fa';

// function Groups() {
//     const [groups, setGroups] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [expandedGroup, setExpandedGroup] = useState(null);
//     const navigate = useNavigate();

//     const companyId = JSON.parse(localStorage.getItem("company"))._id;

//     // Fetch groups
//     const fetchGroups = async (companyId, page = 1) => {
//         setLoading(true);
//         try {
//             const response = await apiCallFetch(`/company/get-groups/${companyId}?page=${page}&limit=10`, 'GET');
//             console.log(response, "gropupssssssssssssss");

//             if (response.success === false) {
//                 setGroups([]);
//                 setError(response.message || 'No groups found for this chapter!');
//             } else if (response?.data && Array.isArray(response.data)) {
//                 setGroups(response.data);
//                 setError(null);
//             } else {
//                 setGroups([]);
//                 setError('Unexpected response structure');
//             }
//         } catch (err) {
//             console.error('API Error:', err);
//             setError('Failed to fetch groups.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Toggle mentee list
//     const toggleMentees = (groupId) => {
//         setExpandedGroup(expandedGroup === groupId ? null : groupId);
//     };

//     // Fetch on component mount
//     useEffect(() => {
//         if (companyId) fetchGroups(companyId);
//     }, [companyId]);

//     const handleRemoveMentor = async (userId, groupId) => {
//         // navigate(`/add-mentor/${userId}`);

//         try {
//             const data = { userID: userId, groupID: groupId };
//             const response = await apiCallFetch('/company/remove-mentor', 'POST', data); // Directly pass the data object

//             console.log("API Response:", response);  // Debugging the API response

//             if (response.success) {  // Check if success is true
//                 setTimeout(() => {
//                     fetchGroups(companyId);
//                 }, 500);
//             } else {
//                 setError(response.message || 'Failed to add mentor.');
//             }
//         } catch (err) {
//             setError('An error occurred while adding the mentor.');
//             console.error('Error during API call:', err); // Log the detailed error
//         }
//     };

//     const handleRemoveMentee = async (userId, groupId) => {
//         // navigate(`/add-mentor/${userId}`);
//         console.log(userId, groupId, "user id, group id");

//         try {
//             const data = { userID: userId, groupID: groupId };
//             const response = await apiCallFetch('/company/remove-mentee', 'POST', data); // Directly pass the data object

//             console.log("API Response:", response);  // Debugging the API response

//             if (response.success) {  // Check if success is true
//                 setTimeout(() => {
//                     fetchGroups(companyId);
//                 }, 500);
//             } else {
//                 setError(response.message || 'Failed to add mentor.');
//             }
//         } catch (err) {
//             setError('An error occurred while removing the mentee.');
//             console.error('Error during API call:', err); // Log the detailed error
//         }
//     };

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center min-h-screen bg-gray-100">
//                 <div className="flex flex-col items-center">
//                     <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
//                     <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
//                 </div>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen bg-gray-50">
//                 <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
//             </div>
//         );
//     }

//     // Group mentees by name and list their quizzes
//     const groupMentees = (group) => {
//         const menteesMap = new Map();

//         group.mentee.forEach((mentee) => {
//             if (!menteesMap.has(mentee._id)) {
//                 menteesMap.set(mentee._id, { name: mentee.profile.name, quizzes: [], Id: mentee._id });
//             }

//             menteesMap.get(mentee._id).quizzes.push(...mentee.quizAttempts);
//         });

//         return Array.from(menteesMap.values());
//     };

//     // return (
//     //     <div className="container mx-auto px-6 py-8 bg-white rounded-lg">
//     //         <h1 className="text-3xl font-bold text-gray-900 mb-6">Manage Mentors</h1>

//     //         {groups.length > 0 ? (
//     //             <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
//     //                 <thead className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
//     //                     <tr>
//     //                         {/* <th className="py-3 px-6 text-left">Group Name</th> */}
//     //                         <th className="py-3 px-6 text-left">Mentor Name</th>
//     //                         <th className="py-3 px-6 text-center">Actions</th>
//     //                     </tr>
//     //                 </thead>
//     //                 <tbody className="text-gray-700">
//     //                     {groups.map((group) => (
//     //                         <React.Fragment key={group._id}>
//     //                             <tr className="border-b hover:bg-gray-100">
//     //                                 {/* <td className="py-4 px-6">{group.groupName}</td> */}
//     //                                 <td className="py-4 px-6">{group?.mentor?.profile?.name}</td>
//     //                                 <td className="py-4 px-6 text-center">
//     //                                     <div className="flex items-center justify-center space-x-2">
//     //                                         <button
//     //                                             onClick={() => toggleMentees(group._id)}
//     //                                             className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
//     //                                         >
//     //                                             {expandedGroup === group._id ? 'Hide Mentees' : 'Show Mentees'}
//     //                                         </button>
//     //                                         <button
//     //                                             onClick={() => navigate(`/add-menttes/${group._id}`)}
//     //                                             className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
//     //                                         >
//     //                                             Add Mentee
//     //                                         </button>
//     //                                         <button
//     //                                             onClick={() => handleRemoveMentor(group.mentor._id, group._id)}
//     //                                             className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-green-700 hover:shadow-lg transition-all duration-300"
//     //                                         >
//     //                                             <FaUserMinus className="mr-2 text-lg" />
//     //                                             <span className="text-sm font-semibold">Mentor</span>
//     //                                         </button>
//     //                                     </div>
//     //                                 </td>
//     //                             </tr>

//     //                             {expandedGroup === group._id && (
//     //                                 <tr>
//     //                                     <td colSpan="3" className="py-4 px-6 bg-gray-50">
//     //                                         <table className="min-w-full">
//     //                                             <thead className="bg-gray-200">
//     //                                                 <tr>
//     //                                                     {/* Ensure both headers have the same classes for consistent styling */}
//     //                                                     <th className="py-3 px-6 text-left bg-gradient-to-r from-blue-500 to-blue-600 text-white">Mentee Name</th>
//     //                                                     <th className="py-3 px-6 text-left bg-gradient-to-r from-blue-500 to-blue-600 text-white">Quiz Attempts</th>
//     //                                                 </tr>
//     //                                             </thead>
//     //                                             <tbody>
//     //                                                 {groupMentees(group).length > 0 ? (
//     //                                                     groupMentees(group).map((mentee) => (
//     //                                                         <tr key={mentee.name} className="border-b hover:bg-gray-50">
//     //                                                             <td className="py-2 px-4">{mentee.name}</td>
//     //                                                             <td className="py-2 px-4">
//     //                                                                 {mentee.quizzes.length > 0 ? (
//     //                                                                     <ul>
//     //                                                                         {mentee.quizzes.map((attempt, index) => (
//     //                                                                             <li key={index} className="mb-2">
//     //                                                                                 <span className="font-semibold">Quiz:</span> {attempt.quiz.description}
//     //                                                                             </li>
//     //                                                                         ))}
//     //                                                                     </ul>
//     //                                                                 ) : (
//     //                                                                     <p>No quizzes attempted</p>
//     //                                                                 )}
//     //                                                             </td>
//     //                                                             <td className="py-2 px-4">
//     //                                                                 {console.log(mentee, "---------iddddddddddddd")}                                                                    <button
//     //                                                                     onClick={() => handleRemoveMentee(mentee.Id, group._id)}
//     //                                                                     className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-red-700 transition-all"
//     //                                                                 >
//     //                                                                     <FaUserMinus className="mr-2 text-lg" />
//     //                                                                     <span className="text-sm font-semibold">Remove Mentee</span>
//     //                                                                 </button>
//     //                                                             </td>

//     //                                                         </tr>
//     //                                                     ))
//     //                                                 ) : (
//     //                                                     <tr>
//     //                                                         <td colSpan="2" className="py-4 px-6 text-center text-gray-500">No mentees found</td>
//     //                                                     </tr>
//     //                                                 )}
//     //                                             </tbody>
//     //                                         </table>
//     //                                     </td>
//     //                                 </tr>
//     //                             )}
//     //                         </React.Fragment>
//     //                     ))}
//     //                 </tbody>

//     //             </table>
//     //         ) : (
//     //             <p className="text-center text-gray-500 mt-8">No mentors available</p>
//     //         )}
//     //     </div>
//     // );


//     return (
//         <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-md">
//             <h1 className="text-3xl font-bold text-gray-900 mb-6">Manage Mentors</h1>

//             {groups.length > 0 ? (
//                 <div className="overflow-x-auto">
//                     <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
//                         <thead className="bg-gradient-to-r bg-olive-green text-white">
//                             <tr>
//                                 <th className="py-3 px-6 text-left">Mentor Name</th>
//                                 <th className="py-3 px-6 text-center">Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody className="text-gray-700">
//                             {groups.map((group) => (
//                                 <React.Fragment key={group._id}>
//                                     <tr
//                                         className={`border-b hover:bg-gray-100 ${expandedGroup === group._id ? 'bg-blue-50' : ''
//                                             }`}
//                                     >
//                                         <td className="py-4 px-6">{group?.mentor?.profile?.name}</td>
//                                         <td className="py-4 px-6 text-center">
//                                             <div className="flex items-center justify-center space-x-2">
//                                                 <button
//                                                     onClick={() => toggleMentees(group._id)}
//                                                     className="flex items-center justify-center bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
//                                                     title={
//                                                         expandedGroup === group._id
//                                                             ? 'Hide Mentees'
//                                                             : 'Show Mentees'
//                                                     }
//                                                 >
//                                                     {expandedGroup === group._id ? (
//                                                         <>
//                                                             <FaEyeSlash className="mr-2 text-lg" />
//                                                             <span className="text-sm font-semibold">Hide Mentees</span>
//                                                         </>
//                                                     ) : (
//                                                         <>
//                                                             <FaEye className="mr-2 text-lg" />
//                                                             <span className="text-sm font-semibold">Show Mentees</span>
//                                                         </>
//                                                     )}
//                                                 </button>
//                                                 <button
//                                                     onClick={() => navigate(`/add-menttes/${group._id}`)}
//                                                     className="flex items-center justify-center bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
//                                                 >
//                                                     <FaUserPlus className="mr-2 text-lg" />
//                                                     <span className="text-sm font-semibold">Mentee</span>
//                                                 </button>
//                                                 <button
//                                                     onClick={() => handleRemoveMentor(group.mentor._id, group._id)}
//                                                     className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-red-700 transition-all duration-300"
//                                                     title="Remove Mentor"
//                                                 >
//                                                     <FaUserMinus className="mr-2 text-lg" />
//                                                     <span className="text-sm font-semibold">Mentor</span>
//                                                 </button>
//                                             </div>

//                                         </td>
//                                     </tr>

// {expandedGroup === group._id && (
//     <tr>
//         <td colSpan="3" className="py-4 px-6 bg-gray-50">
//             <table className="min-w-full">
//                 <thead className="bg-gray-200">
//                     <tr>
//                         <th className="py-3 px-6 text-left bg-gradient-to-r bg-olive-green text-white">
//                             Mentee Name
//                         </th>
//                         <th className="py-3 px-6 text-left bg-gradient-to-r bg-olive-green text-white">
//                             Quiz Attempts
//                         </th>
//                         <th className="py-3 px-6 text-left bg-gradient-to-r bg-olive-green text-white">
//                             Actions
//                         </th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {groupMentees(group).length > 0 ? (
//                         groupMentees(group).map((mentee) => (
//                             <tr key={mentee.name} className="border-b hover:bg-gray-50">
//                                 <td className="py-2 px-4">{mentee.name}</td>
//                                 <td className="py-2 px-4">
//                                     {mentee.quizzes.length > 0 ? (
//                                         <ul>
//                                             {mentee.quizzes.map((attempt, index) => (
//                                                 <li key={index} className="mb-2">
//                                                     <span className="font-semibold">
//                                                         Quiz:
//                                                     </span>{' '}
//                                                     {attempt.quiz.description}
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     ) : (
//                                         <p>No quizzes attempted</p>
//                                     )}
//                                 </td>
//                                 <td className="py-2 px-4">
//                                     <button
//                                         onClick={() =>
//                                             handleRemoveMentee(mentee.Id, group._id)
//                                         }
//                                         className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-red-700 transition-all"
//                                         title="Remove Mentee"
//                                     >
//                                         <FaUserMinus className="mr-2 text-lg" />
//                                         <span className="text-sm font-semibold">
//                                             Mentee
//                                         </span>
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td
//                                 colSpan="3"
//                                 className="py-4 px-6 text-center text-gray-500"
//                             >
//                                 No mentees found
//                             </td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table>
//         </td>
//     </tr>
// )}
//                                 </React.Fragment>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             ) : (
//                 <div className="text-center text-gray-500 mt-8">
//                     <p>No mentors available</p>
//                 </div>
//             )}
//         </div>
//     );

// }

// export default Groups;


import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper'; // Adjust path if needed
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaEnvelope, FaEye, FaEyeSlash, FaPhone, FaTrash, FaTrophy, FaUser, FaUserMinus, FaUserPlus } from 'react-icons/fa';

function Groups() {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedGroup, setExpandedGroup] = useState(null);
    const navigate = useNavigate();

    const companyId = JSON.parse(localStorage.getItem("company"))._id;

    const formatDate = (dateString) => {
        if (!dateString) return 'NOT AT';
        const date = new Date(dateString) || null;
        return date.toLocaleString();
    };
    // Fetch groups
    const fetchGroups = async (companyId, page = 1) => {
        setLoading(true);
        try {
            const response = await apiCallFetch(`/company/get-groups/${companyId}?page=${page}&limit=10`, 'GET');
            console.log(response, "gropupssssssssssssss");

            if (response.success === false) {
                setGroups([]);
                setError(response.message || 'No groups found for this chapter!');
            } else if (response?.data && Array.isArray(response.data)) {
                setGroups(response.data);
                setError(null);
            } else {
                setGroups([]);
                setError('Unexpected response structure');
            }
        } catch (err) {
            console.error('API Error:', err);
            setError('Failed to fetch groups.');
        } finally {
            setLoading(false);
        }
    };

    // Toggle mentee list
    const toggleMentees = (groupId) => {
        setExpandedGroup(expandedGroup === groupId ? null : groupId);
    };

    const handleShowMentees = (groupId, mentor) => {
        navigate('/ViewMenteesByMentor', { state: { mentor, groupId } });
    };

    // Fetch on component mount
    useEffect(() => {
        if (companyId) fetchGroups(companyId);
    }, [companyId]);

    const handleRemoveMentor = async (userId, groupId) => {
        // navigate(`/add-mentor/${userId}`);

        try {
            const data = { userID: userId, groupID: groupId };
            const response = await apiCallFetch('/company/remove-mentor', 'POST', data); // Directly pass the data object

            console.log("API Response:", response);  // Debugging the API response

            if (response.success) {  // Check if success is true
                setTimeout(() => {
                    fetchGroups(companyId);
                }, 500);
            } else {
                setError(response.message || 'Failed to add mentor.');
            }
        } catch (err) {
            setError('An error occurred while adding the mentor.');
            console.error('Error during API call:', err); // Log the detailed error
        }
    };

    const handleRemoveMentee = async (userId, groupId) => {
        // navigate(`/add-mentor/${userId}`);
        console.log(userId, groupId, "user id, group id");

        try {
            const data = { userID: userId, groupID: groupId };
            const response = await apiCallFetch('/company/remove-mentee', 'POST', data); // Directly pass the data object

            console.log("API Response:", response);  // Debugging the API response

            if (response.success) {  // Check if success is true
                setTimeout(() => {
                    fetchGroups(companyId);
                }, 500);
            } else {
                setError(response.message || 'Failed to add mentor.');
            }
        } catch (err) {
            setError('An error occurred while removing the mentee.');
            console.error('Error during API call:', err); // Log the detailed error
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-50">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    // Group mentees by name and list their quizzes
    // const groupMentees = (group) => {
    //     const menteesMap = new Map();

    //     group.mentee.forEach((mentee) => {
    //         if (!menteesMap.has(mentee._id)) {
    //             menteesMap.set(mentee._id, {
    //                 name: mentee.profile.name, quizzes: [], Id: mentee._id,
    //                 score: mentee.quizAttempts.map(attempt => attempt.score || 0), // Handle missing score
    //                 percentage: mentee.quizAttempts.map(attempt => attempt.percentageScore || 0),
    //                 status: mentee.quizAttempts.map(attempt => attempt.status || 'pending'),

    //             });
    //         }

    //         menteesMap.get(mentee._id).quizzes.push(...mentee.quizAttempts);
    //     });

    //     return Array.from(menteesMap.values());
    // };

    const groupMentees = (group) => {
        const menteesMap = new Map();

        group.mentee.forEach((mentee) => {
            if (!menteesMap.has(mentee._id)) {
                menteesMap.set(mentee._id, {
                    name: mentee.profile.name,
                    quizzes: [],
                    Id: mentee._id,
                    scores: mentee.quizAttempts.map((attempt) => attempt.score || 0),
                    percentages: mentee.quizAttempts.map((attempt) => attempt.percentageScore || 0),
                    result: mentee.quizAttempts.map((attempt) => {
                        if (attempt.passed === true) {
                            return "Passed";
                        } else if (attempt.passed === false) {
                            return "Failed";
                        } else {
                            return "Pending";
                        }
                    }),
                    totalPossibleScore: mentee.quizAttempts.map((attempt) => attempt.totalPossibleScore || 0),

                });
            }
            menteesMap.get(mentee._id).quizzes.push(...mentee.quizAttempts);
        });

        return Array.from(menteesMap.values());
    };



    return (
        <div className="container mx-auto px-6 py-8 bg-white rounded-lg">
            <h1 className="text-2xl font-bold mb-6">Manage Mentors</h1>

            {groups.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
                    {groups.map((group) => (
                        <div key={group._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col items-start space-y-4 relative" style={{ minHeight: '100px' }}>
                            <p className="text-sm font-bold text-black-500">Last Login: {formatDate(group?.mentor?.lastLogin ? group?.mentor?.lastLogin : 'NOT AT')} </p>
                            <h2 className="font-bold text-lg text-gray-800 flex items-center space-x-2">
                                <FaUser className="text-blue-500" />
                                <span>{group?.mentor?.profile?.name}</span>
                            </h2>

                            <div className="text-sm text-gray-600">
                                <div className="flex items-center space-x-2">
                                    <FaEnvelope className="text-blue-500" />
                                    <span>{group?.mentor?.email}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaPhone className="text-blue-500" />
                                    <span>{group?.mentor?.profile?.phone || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="flex space-x-1 mt-4">
                                <button
                                    onClick={() => navigate(`/add-menttes/${group._id}`)}
                                    className="flex items-center justify-center bg-blue-600 text-white py-2 px-3 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
                                    title="Add Mentor"
                                >
                                    <FaUserPlus className="mr-2 text-lg" />
                                    <span className="text-sm font-semibold">Mentee</span>
                                </button>
                                <button
                                    onClick={() => handleRemoveMentor(group.mentor._id, group._id)}
                                    className="flex items-center justify-center bg-red-500 text-white py-2 px-3 rounded-lg shadow-md hover:bg-red-700 transition-all duration-300"
                                    title="Remove Mentor"
                                >
                                    <FaUserMinus className="mr-2 text-lg" />
                                    <span className="text-sm font-semibold">Mentor</span>
                                </button>
                                <button
                                    onClick={() => toggleMentees(group._id)}
                                    className="flex items-center justify-center bg-blue-600 text-white py-2 px-3 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
                                    title={expandedGroup === group._id ? 'Hide Mentees' : 'Show Mentees'}
                                >
                                    {expandedGroup === group._id ? (
                                        <>
                                            <FaEyeSlash className="mr-2 text-lg" />
                                            <span className="text-sm font-semibold">Hide Mentees</span>
                                        </>
                                    ) : (
                                        <>
                                            <FaEye className="mr-2 text-lg" />
                                            <span className="text-sm font-semibold">Show Mentees</span>
                                        </>
                                    )}
                                </button>
                            </div>

                            {expandedGroup === group._id && (
                                <div className="mt-4 w-full">
                                    <table className="min-w-full bg-gray-50 rounded-lg">
                                        <thead className="bg-gradient-to-r bg-olive-green text-white">
                                            <tr>
                                                <th className="py-3 px-6 text-left">Mentee Name</th>
                                                <th className="py-3 px-6 text-left">Quiz Attempts</th>
                                                <th className="py-3 px-6 text-left">Total Score</th>
                                                <th className="py-3 px-6 text-left">Score</th>
                                                <th className="py-3 px-6 text-left">Percentage Score</th>
                                                <th className="py-3 px-6 text-left">Result</th>
                                                <th className="py-3 px-6 text-left">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupMentees(group).length > 0 ? (
                                                groupMentees(group).map((mentee) => (
                                                    <tr key={mentee.Id} className="border-b hover:bg-gray-50">
                                                        <td className="py-2 px-4">{mentee.name}</td>
                                                        <td className="py-2 px-4">
                                                            {mentee.quizzes.length > 0 ? (
                                                                <ul>
                                                                    {mentee.quizzes.map((attempt, index) => (
                                                                        <li key={index} className="mb-2">
                                                                            <span className="font-semibold">Quiz:</span> {attempt.quiz.description}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p>No quizzes attempted</p>
                                                            )}
                                                        </td>
                                                        <td className="py-2 px-4">
                                                            <ul>
                                                                {mentee.totalPossibleScore.map((totalPossibleScores, index) => (
                                                                    <li key={index}>{totalPossibleScores}</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                        <td className="py-2 px-4">
                                                            <ul>
                                                                {mentee.scores.map((score, index) => (
                                                                    <li key={index}>{score}</li>
                                                                ))}
                                                            </ul>
                                                        </td>

                                                        <td className="py-2 px-4">
                                                            <ul>
                                                                {mentee.percentages.map((percentage, index) => (
                                                                    <li key={index}>{percentage}%</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                        <td className="py-2 px-4">
                                                            <ul>
                                                                {mentee.result.map((results, index) => (
                                                                    <li key={index}>{results}</li>
                                                                ))}
                                                            </ul>
                                                        </td>
                                                        <td className="py-2 px-4">
                                                            <button
                                                                onClick={() => handleRemoveMentee(mentee.Id, group._id)}
                                                                className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-red-700 transition-all"
                                                                title="Remove Mentee"
                                                            >
                                                                <FaUserMinus className="mr-2 text-lg" />
                                                                <span className="text-sm font-semibold">Mentee</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="py-4 px-6 text-center text-gray-500">
                                                        No mentees found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center text-gray-500 mt-8">
                    <p className="text-xl text-gray-700 font-semibold">No mentors available</p>
                </div>
            )}
        </div>
    );
}

export default Groups;
