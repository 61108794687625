import { useState, useEffect } from 'react';
import { FaThLarge, FaList, FaPlus, FaEdit, FaTrash, FaUser, FaEnvelope, FaPhone, FaTrophy, FaUserPlus, FaSortUp, FaSortDown, FaPaperPlane, FaUserMinus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper'; // Assuming you have an API utility for making requests

const CompanyUsers = () => {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isGridView, setIsGridView] = useState(true); // State for grid/list view toggle
    const [selectedUsers, setSelectedUsers] = useState([]); // State for selected users
    const [sortOrder, setSortOrder] = useState('desc'); // Default to descending
    const navigate = useNavigate();

    useEffect(() => {
        fetchCompanyUsers();
    }, []);

    const fetchCompanyUsers = async () => {
        try {
            setLoading(true);
            const response = await apiCallFetch('/company/get-users', 'GET');
            if (response.status) {
                setUsers(response.data);
                setTotalUsers(response.totalUsers);
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError('Error fetching company users');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (user) => {
        navigate('/add-company-user', { state: { user } });
    };

    const handleDeleteUser = async (userId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this user?');
        if (!isConfirmed) return;

        try {
            const response = await apiCallFetch(`/company/delete-user/${userId}`, 'DELETE');
            if (response.status) {
                setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
            }
        } catch (error) {
            alert(error?.response?.data?.message || 'Error deleting user');
            console.error('Error deleting user:', error);
        }
    };

    const handleAddAttempts = (userId) => {
        navigate(`/company-quiz-report/${userId}`);
    };

    const handleAddMentor = async (userId) => {
        // navigate(`/add-mentor/${userId}`);
        const groupName = "defalt"
        const companyID = JSON.parse(localStorage.getItem("company"))?._id;
        try {
            const data = { userID: userId, groupName, companyID };
            const response = await apiCallFetch('/company/make-mentor', 'POST', data); // Directly pass the data object

            console.log("API Response:", response);  // Debugging the API response

            if (response.success) {  // Check if success is true
                fetchCompanyUsers();
            } else {
                setError(response.message || 'Failed to add mentor.');
            }
        } catch (err) {
            setError('An error occurred while adding the mentor.');
            console.error('Error during API call:', err); // Log the detailed error
        }



    };
    // const handleRemoveMentor = async (userId) => {
    //     // navigate(`/add-mentor/${userId}`);

    //     try {
    //         const data = { userID: userId};
    //         const response = await apiCallFetch('/company/remove-mentor', 'POST', data); // Directly pass the data object

    //         console.log("API Response:", response);  // Debugging the API response

    //         if (response.success) {  // Check if success is true
    //             fetchCompanyUsers();
    //         } else {
    //             setError(response.message || 'Failed to add mentor.');
    //         }
    //     } catch (err) {
    //         setError('An error occurred while adding the mentor.');
    //         console.error('Error during API call:', err); // Log the detailed error
    //     }



    // };
    // const handleRemoveMentee = async (userId) => {
    //     // navigate(`/add-mentor/${userId}`);

    //     try {
    //         const data = { userID: userId};
    //         const response = await apiCallFetch('/company/remove-mentee', 'POST', data); // Directly pass the data object

    //         console.log("API Response:", response);  // Debugging the API response

    //         if (response.success) {  // Check if success is true
    //             fetchCompanyUsers();
    //         } else {
    //             setError(response.message || 'Failed to add mentor.');
    //         }
    //     } catch (err) {
    //         setError('An error occurred while adding the mentor.');
    //         console.error('Error during API call:', err); // Log the detailed error
    //     }



    // };
    const formatDate = (dateString) => {
        if (!dateString) return 'NOT AT';
        const date = new Date(dateString) || null;
        return date.toLocaleString();
    };

    const handleSendMail = async () => {
        if (selectedUsers.length === 0) {
            alert('Please select at least one user to send an email.');
            return;
        }

        const userIds = selectedUsers;
        setLoading(true); // Set loading to true when starting the process

        try {
            const response = await apiCallFetch('/company/send-password-to-users', 'POST', { userIds });
            if (response.status) {
                alert('Emails sent successfully.');
                setSelectedUsers([]); // Clear selections after successful email send
            } else {
                alert('Failed to send emails. Please try again.');
            }
        } catch (error) {
            alert('Error sending emails. Please check your connection.');
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false after the process completes
        }
    };


    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedUsers(users.map((user) => user._id)); // Select all users
        } else {
            setSelectedUsers([]); // Deselect all users
        }
    };

    const handleUserSelect = (userId) => {
        setSelectedUsers((prevSelectedUsers) =>
            prevSelectedUsers.includes(userId)
                ? prevSelectedUsers.filter((id) => id !== userId) // Deselect user
                : [...prevSelectedUsers, userId] // Select user
        );
    };

    const handleSortByLastLogin = () => {
        const sortedUsers = [...users].sort((a, b) => {
            const dateA = new Date(a.lastLogin || 0);
            const dateB = new Date(b.lastLogin || 0);
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setUsers(sortedUsers);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sorting order
    };

    const LoadingSpinner = () => (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
            </div>
        </div>
    );

    const company = JSON.parse(localStorage.getItem("company"));

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4 py-2">
                <h1 className="text-2xl font-bold">Company Users - ({totalUsers})</h1>
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => setIsGridView(true)} // Switch to grid view
                        className={`p-2 rounded-full ${isGridView ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                    >
                        <FaThLarge />
                    </button>
                    <button
                        onClick={() => setIsGridView(false)} // Switch to list (table) view
                        className={`p-2 rounded-full ${!isGridView ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                    >
                        <FaList />
                    </button>
                    {!isGridView && ( // Show "Send Mail" button only in list view
                        <button
                            onClick={handleSendMail}
                            className="flex items-center justify-center bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300"
                            disabled={loading} // Disable the button while loading
                            title="Send Password"
                        >
                            {loading ? (
                                "Sending..." // Show "Sending..." when loading
                            ) : (
                                <>
                                    <FaPaperPlane className="mr-2 text-lg" />
                                    <span className="text-sm font-semibold">Send Password</span>
                                </>
                            )}
                        </button>

                    )}
                    <button
                        onClick={() => navigate('/add-company-user')}
                        className="flex items-center justify-center bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300"
                        title="Add User"
                    >
                        <FaPlus className="mr-2 text-lg" />
                        <span className="text-sm font-semibold">Add User</span>
                    </button>

                </div>
            </div>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : users.length === 0 ? (
                <div className="p-6 rounded-xl text-center">
                    <p className="text-xl text-gray-700 font-semibold">No users in this company.</p>
                    <p className="text-sm text-gray-500 mt-2">It seems there are no users available right now. Please add users to get started.</p>
                </div>
            ) : (
                isGridView ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {users.map((user) => (
                            <div key={user._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col items-start space-y-4 relative" style={{ minHeight: '100px' }}>
                                <p className="text-sm font-bold text-black-500">Last Login: {formatDate(user.lastLogin)}</p>
                                <h2 className="font-bold text-lg text-gray-800 flex items-center space-x-2">
                                    <FaUser className="text-blue-500" />
                                    <span>{user.profile.name}</span>
                                </h2>
                                <div className="text-sm text-gray-600">
                                    <div className="flex items-center space-x-2">
                                        <FaEnvelope className="text-blue-500" />
                                        <span>{user.email}</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <FaPhone className="text-blue-500" />
                                        <span>{user.profile.phone || 'N/A'}</span>
                                    </div>
                                </div>

                                <div className="absolute top-2 right-2 flex flex-col items-end space-y-2">
                                    <div className="flex space-x-2">
                                        <button onClick={() => handleUpdate(user)} className="text-gray-600 hover:text-blue-500">
                                            <FaEdit />
                                        </button>
                                        <button onClick={() => handleDeleteUser(user._id)} className="text-gray-600 hover:text-red-500">
                                            <FaTrash />
                                        </button>
                                    </div>
                                </div>

                                {company.hasAccess.quiz && (
                                    <div className="flex space-x-4 mt-4">
                                        {/* View Attempts Button */}
                                        <button
                                            onClick={() => handleAddAttempts(user._id)}
                                            className="flex items-center justify-center bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-green-700 hover:shadow-lg transition-all duration-300"
                                        >
                                            <FaTrophy className="mr-2 text-lg" />
                                            <span className="text-sm font-semibold">Attempts</span>
                                        </button>

                                        {/* Make Mentor Button */}
                                        {user.role == 'staff' && (
                                            <button
                                                onClick={() => handleAddMentor(user._id)}
                                                className="flex items-center justify-center bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-green-700 hover:shadow-lg transition-all duration-300"
                                            >
                                                <FaUserPlus className="mr-2 text-lg" />
                                                <span className="text-sm font-semibold">Mentor</span>
                                            </button>
                                        )}
                                        {/* {user.role == 'mentor' && (
                                            <button
                                                // onClick={() => handleAddMentor(user._id)}
                                                onClick={() => handleRemoveMentor(user._id)}
                                                className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-green-700 hover:shadow-lg transition-all duration-300"
                                            >
                                                <FaUserMinus className="mr-2 text-lg" />
                                                <span className="text-sm font-semibold">Mentor</span>
                                            </button>
                                        )} */}
                                        {/* {user.role == 'mentee' && (
                                            <button
                                                // onClick={() => handleAddMentor(user._id)}
                                                onClick={() => handleRemoveMentee(user._id)}
                                                className="flex items-center justify-center bg-red-500 text-white py-2 px-6 rounded-lg shadow-md"
                                            >
                                                <FaUserMinus className="mr-2 text-lg" />
                                                <span className="text-sm font-semibold">Mentee</span>
                                            </button>
                                        )} */}
                                    </div>
                                )}

                            </div>
                        ))}
                    </div>
                ) : (
                    <table className="table-auto w-full text-left">
                        <thead>
                            <tr className="bg-gray-200 ">
                                <th className="px-4 py-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.length === users.length}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th className="px-4 py-2">Name</th>
                                <th className="px-4 py-2">Email</th>
                                <th className="px-4 py-2">Phone</th>
                                <th className="px-4 py-2 cursor-pointer flex items-center gap-1" onClick={handleSortByLastLogin}>
                                    Last Login
                                    {sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />}
                                </th>

                                <th className="px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user._id} className="border-t">
                                    <td className="px-4 py-2">
                                        <input
                                            type="checkbox"
                                            checked={selectedUsers.includes(user._id)}
                                            onChange={() => handleUserSelect(user._id)}
                                        />
                                    </td>
                                    <td className="px-4 py-2">{user.profile.name}</td>
                                    <td className="px-4 py-2">{user.email}</td>
                                    <td className="px-4 py-2">{user.profile.phone || 'N/A'}</td>
                                    <td className="px-4 py-2">{formatDate(user.lastLogin)}</td>
                                    <td className="px-4 py-2">
                                        <button
                                            onClick={() => handleUpdate(user)}
                                            className="mr-2"
                                        >
                                            <FaEdit />
                                        </button>
                                        <button
                                            onClick={() => handleDeleteUser(user._id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            )}
        </div>
    );
};

export default CompanyUsers;
