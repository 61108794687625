import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { apiCallFetch } from '../api/apiHelper';
import { useNavigate, useLocation } from 'react-router-dom';

const AddQuiz = () => {
    const [topics, setTopics] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        totalQuestions: '',
        timePerQuestion: '',
        passingScore: '',
        allowedCompanies: [],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await apiCallFetch('/topic/get', 'GET');
                if (response.status) {
                    setTopics(response.data);
                    // If editing, set selectedTopic once topics are fetched
                    if (location.state?.quiz) {
                        const quizToEdit = location.state.quiz;
                        const topicId = quizToEdit.topics[0]?._id || quizToEdit.topics[0];
                        setSelectedTopic(topicId);
                    }
                } else {
                    setError('Failed to load topics.');
                }
            } catch (error) {
                setError('Failed to load topics.');
            }
        };

        const fetchCompanies = async () => {
            setLoading(true);
            try {
                const response = await apiCallFetch('/company/list', 'GET');
                if (response.status) {
                    setCompanies(response.data.map(company => ({
                        value: company._id,
                        label: company.name,
                    })));
                } else {
                    setError(response.message);
                }
            } catch (error) {
                setError('Error fetching companies.');
            } finally {
                setLoading(false);
            }
        };

        fetchTopics();
        fetchCompanies();

        if (location.state?.quiz) {
            const quizToEdit = location.state.quiz;
            setFormData({
                name: quizToEdit.name,
                description: quizToEdit.description,
                totalQuestions: quizToEdit.totalQuestions,
                timePerQuestion: quizToEdit.timePerQuestion,
                passingScore: quizToEdit.passingScore,
                allowedCompanies: quizToEdit.allowedCompanies.map(company => ({
                    value: company._id,
                    label: company.name,
                })) || [],
            });
        }
    }, [location.state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTopicChange = (e) => {
        setSelectedTopic(e.target.value);
    };

    const handleCompanyChange = (selectedOptions) => {
        setFormData(prev => ({ ...prev, allowedCompanies: selectedOptions }));
    };

    const validateForm = () => {
        const { name, description, totalQuestions, timePerQuestion, passingScore } = formData;
        if (!name.trim() || !description.trim() || !selectedTopic || (formData.allowedCompanies.length === 0 && !location.state?.quiz)) {
            setError('Please complete all fields.');
            return false;
        }
        if (totalQuestions <= 0) {
            setError('Total questions must be a positive number.');
            return false;
        }
        if (timePerQuestion <= 0) {
            setError('Time per question must be a positive number.');
            return false;
        }
        if (passingScore <= 0) {
            setError('Passing score must be a positive number.');
            return false;
        }
        setError(null);
        return true;
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setError(null);
    //     setSuccess(null);

    //     if (!validateForm()) {
    //         setLoading(false);
    //         return;
    //     }

    //     const newQuiz = {
    //         name: formData.name,
    //         description: formData.description,
    //         topics: location.state?.quiz ? [selectedTopic] : [selectedTopic], // Convert for update if needed
    //         totalQuestions: formData.totalQuestions,
    //         timePerQuestion: formData.timePerQuestion,
    //         passingScore: formData.passingScore,
    //         allowedCompanies: location.state?.quiz ? undefined : formData.allowedCompanies.map(company => company.value),
    //     };

    //     try {
    //         let response;
    //         if (location.state?.quiz) {
    //             const quizId = location.state.quiz._id;
    //             response = await apiCallFetch(`/quiz/update/${quizId}`, 'PUT', newQuiz);
    //         } else {
    //             response = await apiCallFetch('/quiz/create', 'POST', newQuiz);
    //         }

    //         if (response.success) {
    //             setSuccess(location.state?.quiz ? 'Quiz updated successfully!' : 'Quiz created successfully!');
    //             navigate('/quiz-master');
    //         } else {
    //             setError(response.message || 'Failed to save quiz.');
    //         }
    //     } catch (error) {
    //         setError('Error saving quiz. Please try again.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        if (!validateForm()) {
            setLoading(false);
            return;
        }

        const newQuiz = {
            name: formData.name,
            description: formData.description,
            topics: location.state?.quiz ? [selectedTopic] : [selectedTopic], // Convert for update if needed
            totalQuestions: formData.totalQuestions,
            timePerQuestion: formData.timePerQuestion,
            passingScore: formData.passingScore,
            allowedCompanies: location.state?.quiz ? undefined : formData.allowedCompanies.map(company => company.value),
        };

        try {
            let response;
            if (location.state?.quiz) {
                const quizId = location.state.quiz._id;
                response = await apiCallFetch(`/quiz/update/${quizId}`, 'PUT', newQuiz);
            } else {
                response = await apiCallFetch('/quiz/create', 'POST', newQuiz);
            }

            // Log the response to check its structure
            console.log(response, "responseresponseresponse");

            // Check if success is false in the response, and if so, set the error message
            if (!response.success) {
                setError(response.message || 'Failed to save quiz.');
            } else {
                setSuccess(location.state?.quiz ? 'Quiz updated successfully!' : 'Quiz created successfully!');
                navigate('/quiz-master');
            }

        } catch (error) {
            if (error.response?.data) {
                setError(error.response.data.message || 'Failed to save quiz.');
            } else {
                setError('Error saving quiz. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className=" bg-white shadow-xl rounded-lg container mx-auto max-w-2xl px-6 py-8">
            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-gray-700 text-2xl mr-3"
                    onClick={() => navigate('/quiz-master')}
                >
                    ←
                </button>
                <h1 className="text-3xl font-semibold">
                    {location.state?.quiz ? 'Update Quiz' : 'Add Quiz'}
                </h1>
            </div>
            {error && <p className="bg-red-100 text-red-600 border border-red-300 p-3 rounded mb-6">{error}</p>}
            {success && <p className="bg-green-100 text-green-600 border border-green-300 p-3 rounded mb-6">{success}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Quiz Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    />
                </div>

                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    />
                </div>

                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Topic</label>
                    <select
                        value={selectedTopic}
                        onChange={handleTopicChange}
                        className="border p-2 rounded-md w-full"
                        required
                    >
                        <option value="" disabled>Select a topic</option>
                        {topics.map(topic => (
                            <option key={topic._id} value={topic._id}>{topic.name}</option>
                        ))}
                    </select>
                </div>

                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Companies</label>
                    <Select
                        isMulti
                        options={companies}
                        value={formData.allowedCompanies}
                        onChange={handleCompanyChange}
                        className="w-full"
                    />
                </div>

                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Total Questions</label>
                    <input
                        type="number"
                        name="totalQuestions"
                        value={formData.totalQuestions}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    />
                </div>

                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Time Per Question (in seconds)</label>
                    <input
                        type="number"
                        name="timePerQuestion"
                        value={formData.timePerQuestion}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    />
                </div>

                <div className="mb-5">
                    <label className="block text-lg font-medium mb-2">Passing Score</label>
                    <input
                        type="number"
                        name="passingScore"
                        value={formData.passingScore}
                        onChange={handleInputChange}
                        className="border-gray-300 border p-3 rounded-md w-full focus:outline-blue-500"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="bg-blue-500 text-white px-6 py-3 rounded-md w-full"
                    disabled={loading}
                >
                    {loading ? 'Loading...' : (location.state?.quiz ? 'Update Quiz' : 'Add Quiz')}
                </button>
            </form>
        </div>
    );
};

export default AddQuiz;
