import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper';

const AddCompanyUsers = () => {
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check if we are in edit mode
        if (location?.state?.user) {
            setIsEditMode(true);
            const { user } = location.state;
            formik.setValues({
                email: user.email,
                name: user.profile.name,
                phone: user.profile.phone,
                whatsapp: user.profile.whatsapp || '',
            });
        }
    }, [location.state]);

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phone: '',
            whatsapp: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone: Yup.string()
                .matches(/^(?:\+91)?[0-9]{10}$/, 'Must start with +91 or be 10 digits')
                .min(10, 'Must be at least 10 digits')
                .max(15, 'Must be 15 digits or less')
                .required('Phone number is required'),
            whatsapp: Yup.string()
                .matches(/^[0-9]{10}$/, 'WhatsApp number must be exactly 10 digits and contain only numbers')
                .nullable(),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setSuccessMessage(''); // Clear previous success message

            try {
                const endpoint = isEditMode ? `/company/update-user/${location?.state?.user?._id}` : '/company/create-user';
                const method = isEditMode ? 'PUT' : 'POST';
                const requestBody = {
                    userId: isEditMode ? location?.state?.user?._id : undefined,
                    ...values,
                };

                const response = await apiCallFetch(endpoint, method, requestBody);

                if (response?.status) {
                    setSuccessMessage(isEditMode ? 'User updated successfully!' : 'User added successfully!');
                    formik.resetForm();
                    setTimeout(() => {
                        navigate('/company-users');
                    }, 2000);
                } else {
                    formik.setErrors({ general: response?.message || 'An unknown error occurred' });
                }
            } catch (err) {
                const errorMessage = err.response?.data?.message || 'Error adding user: ' + (err.message || err);
                formik.setErrors({ general: errorMessage });
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className="p-8 max-w-lg mx-auto bg-white shadow-lg rounded-lg">
            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-blue-500 text-3xl mr-4"
                    onClick={() => navigate(-1)}
                >
                    ←
                </button>
                <h1 className="text-2xl font-semibold text-center text-gray-800 flex-1">
                    {isEditMode ? 'Update User' : 'Create User'}
                </h1>
            </div>

            {successMessage && (
                <div className="bg-green-100 text-green-700 p-3 rounded-lg mb-4 animate-fadeIn">
                    {successMessage}
                </div>
            )}

            {formik.errors.general && (
                <div className="bg-red-100 text-red-700 p-3 rounded-lg mb-4">
                    {formik.errors.general}
                </div>
            )}

            <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Enter Name"
                        className="border p-3 w-full rounded-md mt-2"
                    />
                    {formik.errors.name && <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div>}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Enter Email"
                        className="border p-3 w-full rounded-md mt-2"
                    />
                    {formik.errors.email && <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="phone">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        placeholder="Enter Phone Number"
                        className="border p-3 w-full rounded-md mt-2"
                    />
                    {formik.errors.phone && <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700" htmlFor="whatsapp">WhatsApp</label>
                    <input
                        type="text"
                        id="whatsapp"
                        name="whatsapp"
                        value={formik.values.whatsapp}
                        onChange={formik.handleChange}
                        placeholder="Enter WhatsApp Number"
                        className="border p-3 w-full rounded-md mt-2"
                    />
                    {formik.errors.whatsapp && <div className="text-red-500 text-sm mt-1">{formik.errors.whatsapp}</div>}
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow hover:bg-blue-600 transition-all duration-300"
                        disabled={loading}
                    >
                        {loading ? (isEditMode ? 'Updating...' : 'Creating...') : (isEditMode ? 'Update User' : 'Add User')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddCompanyUsers;
