import React, { useState, useEffect } from 'react';
import { apiCallFetch } from '../api/apiHelper';
import moment from 'moment/moment';
import { EyeIcon } from 'lucide-react';
import '../styles/give.css';

const ConnectionRequest = () => {
    const [connectionRequests, setConnectionRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedRequest, setSelectedRequest] = useState(null); // State for selected request
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

    useEffect(() => {
        fetchConnectionRequests();
    }, [page]);

    console.log(connectionRequests, "------------------request connection");

    const fetchConnectionRequests = async () => {
        try {
            const companyId = JSON.parse(localStorage.getItem('company'))._id;

            const response = await apiCallFetch(`/connection/my-all-requests/${companyId}`, "POST", { page, limit });
            console.log(response, "======connection - requests=======");

            if (response.success) {
                setConnectionRequests(response.data);
                setTotalPages(response.meta.totalPages);
            } else {
                setError(response.message);
            }
        } catch (err) {
            console.error("Failed to fetch connection requests", err);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setLoading(true);
            setPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setLoading(true);
            setPage(prevPage => prevPage - 1);
        }
    };

    const handleEyeClick = (request) => {
        setSelectedRequest(request);
        setIsModalOpen(true); // Open modal
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close modal
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="container mx-auto p-4 text-red-500">{error}</div>;
    }

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6">Connection Requests {connectionRequests?.meta?.totalRequests}</h1>

            {connectionRequests.length > 0 ? (
                <div className="overflow-x-auto bg-white shadow-lg rounded-lg border border-gray-200">
                    <table className="min-w-full table-auto text-sm text-gray-700">
                        <thead className="bg-olive-green">
                            <tr>
                                <th className="px-6 py-3 text-left font-medium text-white border-b">Referral Name</th>
                                <th className="px-6 py-3 text-left font-medium text-white border-b">Referral Creator</th>
                                <th className="px-6 py-3 text-left font-medium text-white border-b">Requester Name</th>
                                <th className="px-6 py-3 text-left font-medium text-white border-b">Requested Time</th>
                                <th className="px-6 py-3 text-left font-medium text-white border-b">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {connectionRequests.map((request, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 border-b">{request.referral.name}</td>
                                    <td className="px-6 py-4 border-b">{request.referral.details.profile.name}</td>
                                    <td className="px-6 py-4 border-b">{request.requester.profile.name}</td>
                                    <td className="px-6 py-4 border-b">{moment(request.requestedTime).format("HH:mm:ss DD/MM/YYYY")}</td>
                                    <td className="px-6 py-4 border-b">
                                        <button
                                            className="px-4 py-2 rounded focus:outline-none focus:ring focus:ring-blue-300"
                                            onClick={() => handleEyeClick(request)} // Pass the request object here
                                        >
                                            <EyeIcon className="h-5 w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-center text-gray-600 text-lg mt-4">No connection requests found.</p>
            )}

            {/* Modal for displaying details */}

            {/* {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-md w-11/12 sm:max-w-xs md:max-w-md lg:max-w-lg xl:max-w-2xl transform transition-all duration-300 ease-in-out scale-100">

                        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-4 border-b pb-3">Referral Details</h2>
                        {selectedRequest && selectedRequest.referral && (
                            <div className="space-y-4">
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>Name:</strong> <span className="text-gray-800">{selectedRequest.referral.name}</span></p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>City:</strong> <span className="text-gray-800">{selectedRequest.referral.city}</span></p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>State:</strong> <span className="text-gray-800">{selectedRequest.referral.state}</span></p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>Email:</strong> <span className="text-gray-800">{selectedRequest.referral.email || 'N/A'}</span></p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>Phone:</strong> <span className="text-gray-800">{selectedRequest.referral.phone || 'N/A'}</span></p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>Company:</strong> <span className="text-gray-800">{selectedRequest.referral.company || 'N/A'}</span></p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-lg text-gray-600"><strong>Department:</strong> <span className="text-gray-800">{selectedRequest.referral.department || 'N/A'}</span></p>
                                </div>
                            </div>
                        )}
                        <div className="mt-6 flex justify-center">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md font-medium hover:bg-blue-600 transition duration-200 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )} */}

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-2xl w-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 p-6 transform transition-all duration-300 ease-in-out scale-100">

                        {/* Modal Header */}
                        <div className="flex justify-between items-center mb-8">
                            <h2 className="text-2xl font-semibold text-gray-800">Referral Details</h2>
                            <button
                                onClick={closeModal}
                                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        {/* Referral Details Grid */}
                        {selectedRequest && selectedRequest.referral && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                {/* Name */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">Name</p>
                                    <div className="bg-[#e9e9ed]  p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.name}</div>
                                </div>

                                {/* City */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">City</p>
                                    <div className="bg-[#e9e9ed] p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.city}</div>
                                </div>

                                {/* State */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">State</p>
                                    <div className="bg-[#e9e9ed] p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.state}</div>
                                </div>

                                {/* Email */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">Email</p>
                                    <div className="bg-[#e9e9ed] p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.email || 'N/A'}</div>
                                </div>

                                {/* Phone */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">Phone</p>
                                    <div className="bg-[#e9e9ed] p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.phone || 'N/A'}</div>
                                </div>

                                {/* Company */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">Company</p>
                                    <div className="bg-[#e9e9ed] p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.company || 'N/A'}</div>
                                </div>

                                {/* Department */}
                                <div className="flex flex-col space-y-2">
                                    <p className="text-lg font-medium text-gray-600">Department</p>
                                    <div className="bg-[#e9e9ed] p-4 rounded-lg text-lg text-gray-800">{selectedRequest.referral.department || 'N/A'}</div>
                                </div>
                            </div>
                        )}

                        {/* Close Button */}
                        <div className="mt-8 flex justify-center">
                            <button
                                className="bg-blue-500 text-white px-6 py-3 rounded-lg font-semibold text-lg hover:bg-blue-600 transition duration-200 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}



            <div className="flex justify-between items-center mt-6">
                <button
                    onClick={handlePrevPage}
                    className="bg-olive-green text-white px-6 py-2 rounded-md hover:bg-olive-green-dark transition-all disabled:opacity-50"
                    disabled={page <= 1}
                >
                    Previous
                </button>
                <span className="text-lg text-gray-600">
                    Page {page} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    className="bg-olive-green text-white px-6 py-2 rounded-md hover:bg-olive-green-dark transition-all disabled:opacity-50"
                    disabled={page >= totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );


};

export default ConnectionRequest;
