import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { apiCallFetch } from '../api/apiHelper';

function AddMentees() {
    const { groupID } = useParams();
    const navigate = useNavigate(); // Initialize navigate hook
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch available users
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await apiCallFetch('/company/get-users', 'GET');
                if (response.status) {
                    // setAvailableUsers(response.data);
                    const filteredUsers = response.data.filter(user => user.role !== 'mentor' && user.role !== 'mentee');
                    setAvailableUsers(filteredUsers);
                } else {
                    setErrorMessage(response.message || 'Failed to fetch users.');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setErrorMessage('An error occurred while fetching users.');
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    // Handle selecting a user
    const handleSelectUser = (user) => {
        setSelectedUsers((prev) => [...prev, user]);
        setAvailableUsers((prev) => prev.filter((u) => u._id !== user._id));
    };

    // Handle removing a selected user
    const handleRemoveUser = (user) => {
        setAvailableUsers((prev) => [...prev, user]);
        setSelectedUsers((prev) => prev.filter((u) => u._id !== user._id));
    };

    const handleAddMentees = async () => {
        if (selectedUsers.length === 0) {
            setErrorMessage('Please select at least one user to add as mentees.');
            return;
        }

        const payload = {
            groupID: groupID,
            userIDs: selectedUsers.map((user) => user._id),
        };

        try {
            const response = await apiCallFetch('/company/make-mentee', 'POST', payload);

            console.log(response);  // Debugging the response object

            if (response.success) {  // Checking for success in the response
                // Success message and reset state
                setSuccessMessage(response.message);  // Set success message from response
                setErrorMessage(''); // Clear error message
                setSelectedUsers([]); // Clear selected users

                // Wait before redirecting to ensure UI updates
                setTimeout(() => {
                    navigate('/groups');  // Redirect to groups after success
                }, 1000);  // 1-second delay
            } else {
                // If success flag is false, show a custom error message
                setErrorMessage(response.message || 'Failed to add users to the group.');
            }
        } catch (error) {
            console.error('Error making mentees:', error);
            setErrorMessage('An error occurred while adding the mentees. Please try again.');
        }
    };


    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    // return (
    //     <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg max-w-3xl">
    //         {/* Back Button and Heading in one line */}
    //         <div className="flex items-center mb-6">
    //             <button
    //                 type="button"
    //                 className="text-black-500 text-3xl mr-2 hover:text-blue-500 transition duration-200"
    //                 onClick={() => navigate(-1)} // Navigate back to the previous page
    //             >
    //                 ←
    //             </button>
    //             <h1 className="text-3xl font-bold text-gray-900">Add Mentees to Group</h1>
    //         </div>

    //         {/* Success/Error Messages */}
    //         <div className="mb-4">
    //             {successMessage && <p className="text-green-600 bg-green-100 p-3 rounded-lg">{successMessage}</p>}
    //             {errorMessage && <p className="text-red-600 bg-red-100 p-3 rounded-lg">{errorMessage}</p>}
    //         </div>

    //         {/* Selected Users */}
    //         <div className="mb-4">
    //             <h2 className="text-lg font-semibold mb-2">Selected Users:</h2>
    //             <div className="flex flex-wrap gap-2">
    //                 {selectedUsers.map((user) => (
    //                     <div
    //                         key={user._id}
    //                         className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full"
    //                     >
    //                         <span>{user.profile.name}</span>
    //                         <button
    //                             className="ml-2 text-red-500 hover:text-red-700 transition-all"
    //                             onClick={() => handleRemoveUser(user)}
    //                         >
    //                             ✕
    //                         </button>
    //                     </div>
    //                 ))}
    //                 {selectedUsers.length === 0 && (
    //                     <p className="text-gray-500">No users selected yet.</p>
    //                 )}
    //             </div>
    //         </div>

    //         {/* User Dropdown */}
    //         <div className="mb-4">
    //             <label htmlFor="userSelect" className="text-lg font-semibold mb-2 block">
    //                 Select Users to Add as Mentees:
    //             </label>
    //             <select
    //                 id="userSelect"
    //                 className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
    //                 onChange={(e) => {
    //                     const user = availableUsers.find((u) => u._id === e.target.value);
    //                     if (user) handleSelectUser(user);
    //                 }}
    //                 value=""
    //             >
    //                 <option value="">-- Select User --</option>
    //                 {availableUsers.map((user) => (
    //                     <option key={user._id} value={user._id}>
    //                         {user.profile.name}
    //                     </option>
    //                 ))}
    //             </select>
    //         </div>

    //         {/* Add Mentees Button */}
    //         <button
    //             onClick={handleAddMentees}
    //             // disabled={selectedUsers.length === 0}
    //             className="w-full py-2 px-4 rounded-lg text-white bg-blue-700 hover:bg-blue-500 focus:outline-none transition-all">
    //             Add Mentees
    //         </button>
    //     </div>
    // );

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
            <div className="w-full max-w-4xl p-14 md:p-8 bg-white rounded-lg shadow-xl">
                {/* Back Button and Heading in one line */}
                <div className="flex items-center">
                    <button
                        type="button"
                        className="text-3xl text-gray-700 hover:text-blue-600 transition duration-200 mr-4"
                        onClick={() => navigate(-1)} // Navigate back to the previous page
                    >
                        ←
                    </button>
                    <h1 className="text-3xl font-bold text-gray-800">Add Mentees to Group</h1>
                </div>

                {/* Success/Error Messages */}
                <div className="mb-6">
                    {successMessage && (
                        <p className="text-green-700 bg-green-100 p-4 rounded-lg shadow-md text-lg">
                            {successMessage}
                        </p>
                    )}
                    {errorMessage && (
                        <p className="text-red-700 bg-red-100 p-4 rounded-lg shadow-md text-lg">
                            {errorMessage}
                        </p>
                    )}
                </div>

                {/* Selected Users */}
                <div className="mb-6">
                    <h2 className="text-xl font-semibold mb-3 text-gray-800">Selected Users:</h2>
                    <div className="flex flex-wrap gap-3">
                        {selectedUsers.map((user) => (
                            <div
                                key={user._id}
                                className="flex items-center bg-blue-100 text-blue-700 px-4 py-2 rounded-full text-sm"
                            >
                                <span>{user.profile.name}</span>
                                <button
                                    className="ml-2 text-red-600 hover:text-red-800 transition-all"
                                    onClick={() => handleRemoveUser(user)}
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                        {selectedUsers.length === 0 && (
                            <p className="text-gray-500 text-sm">No users selected yet.</p>
                        )}
                    </div>
                </div>

                {/* User Dropdown */}
                <div className="mb-6">
                    <label htmlFor="userSelect" className="text-lg font-semibold mb-2 block text-gray-700">
                        Select Users to Add as Mentees:
                    </label>
                    <select
                        id="userSelect"
                        className="w-full p-3 border border-gray-300 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-600"
                        onChange={(e) => {
                            const user = availableUsers.find((u) => u._id === e.target.value);
                            if (user) handleSelectUser(user);
                        }}
                        value=""
                    >
                        <option value="">-- Select User --</option>
                        {availableUsers.map((user) => (
                            <option key={user._id} value={user._id}>
                                {user.profile.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Add Mentees Button */}
                <button
                    onClick={handleAddMentees}
                    className="w-full py-3 px-5 rounded-lg text-white bg-blue-600 hover:bg-blue-500 focus:outline-none transition-all text-lg mt-6"
                >
                    Add Mentees
                </button>
            </div>
        </div>
    );

}

export default AddMentees;
