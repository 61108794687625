// ViewMenteesByMentor.js
import React from 'react';
import { useLocation } from 'react-router-dom';

function ViewMenteesByMentor() {
    const location = useLocation();
    const { mentor, groupId } = location.state || {};

    console.log(location, "------mentor location------");

    // If no mentor data is found, return an error message
    if (!mentor) return <p>No mentor data found.</p>;

    // If mentor has no mentees, display a different message
    const mentees = Array.isArray(mentor.mentee) ? mentor.mentee : [];

    return (
        <div>
            <h1>Mentee Details for {mentor.profile.name}</h1>
            <p>Group ID: {groupId}</p>
            <h3>Mentees List</h3>
            {mentees.length > 0 ? (
                <ul>
                    {mentees.map(mentee => (
                        <li key={mentee._id}>{mentee.profile.name}</li>
                    ))}
                </ul>
            ) : (
                <p>No mentees found for this mentor.</p>
            )}
        </div>
    );
}

export default ViewMenteesByMentor;
