import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper'; // Assuming apiCallFetch is a utility function for API calls.
import Dashboard, { LeaderboardDashboard } from './Dashboard';
import '../styles/give.css';


const CompanyDashboard = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({
        user: 0,
        mentors: 0,
        quizzes: 0,
        allReferrals: 0, // Added for Global Gives
    });
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedView, setSelectedView] = useState("allTime"); // Track the selected view (weekly or allTime)



    const fetchDashboardData = async () => {
        setLoading(true);
        setError(null);
        try {
            const companyID = JSON.parse(localStorage.getItem('company'))?._id;
            const response = await apiCallFetch(`/company/get-dashbord/${companyID}`, 'GET');
            if (response.success) {
                setDashboardData(response.data);
            } else {
                setError('Failed to fetch company dashboard data');
            }
        } catch (err) {
            setError('Error fetching company dashboard data');
        } finally {
            // setLoading(false);
        }
    };

    const fetchData = async (view) => {
        console.log(view, "viewiiiiieiieieieiei")
        // setLoading(true);
        setError(null);
        try {
            const companyID = JSON.parse(localStorage.getItem('company'))?._id;
            const endpoint = view === "weekly"
                ? `/company/get-weekly-dashboard-bycompany/${companyID}`
                : "/company/get-company-dashboard";

            console.log(endpoint, "ooooooooo");


            const response = await apiCallFetch(endpoint, "GET");
            if (response.status) {
                setData(response.data);
            } else {
                setError('Failed to fetch dashboard data');
            }
        } catch (err) {
            setError('Error fetching company dashboard data');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchDashboardData();
        fetchData(selectedView);
    }, [localStorage.getItem('company'), selectedView]);



    const handleNavigate = (path) => {
        navigate(path);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-[#534B9D]">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
                    <p className="mt-4 text-white font-semibold">Loading...</p>
                </div>
            </div>
        );
    } if (error) return <div>{error}</div>;

    const companyString = localStorage.getItem('company');
    const company = companyString ? JSON.parse(companyString) : null;

    const loggedInName = company.name;


    return (
        <>
            <div className='contant-bg' style={{ fontFamily: 'Arial, sans-serif', minHeight: '100vh' }}>
                <h1 style={{ fontSize: '32px', padding: '30px 24px 16px', fontWeight: 'bold', marginBottom: '20px', color: 'white' }}>
                    {loggedInName} Dashboard
                </h1>

                <div className='chapter-dashboard'>
                    {/* Total Users Card */}
                    <div className='chapter-call'>
                        <div>
                            <div style={iconStyle}>👥</div>
                            <h2 style={titleStyle}>Total Users</h2>
                            <p style={textStyle}>{dashboardData.user} Users</p>
                            <button style={buttonStyle} onClick={() => handleNavigate('/company-users')}>
                                View Users
                            </button>
                        </div>
                    </div>


                    {/* Global Gives Card */}
                    <div className='chapter-call'>
                        <div>
                            <div style={iconStyle}>🤝</div>
                            <h2 style={titleStyle}>Total Gives</h2>
                            <p style={textStyle}>{dashboardData.allReferrals} Gives</p>
                            <button style={buttonStyle} onClick={() => handleNavigate('/global-referrals')}>
                                View Gives
                            </button>
                        </div>
                    </div>

                    {/* Total Mentors Card */}
                    {company.hasAccess.quiz && (
                        <div className='chapter-call'>
                            <div>
                                <div style={iconStyle}>👨‍🏫</div>
                                <h2 style={titleStyle}>Total Mentors</h2>
                                <p style={textStyle}>{dashboardData.mentors} Mentors</p>
                                <button style={buttonStyle} onClick={() => handleNavigate('/groups')}>
                                    View Mentors
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Total Quizzes Card */}
                    {company.hasAccess.quiz && (
                        <div className='chapter-call'>
                            <div>
                                <div style={iconStyle}>📚</div>
                                <h2 style={titleStyle}>Total Quizzes</h2>
                                <p style={textStyle}>{dashboardData.quizzes} Quizzes</p>
                                <button style={buttonStyle} onClick={() => handleNavigate('/company-quiz')}>
                                    View Quizzes
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className='leaderboar-animation'>
                    {Object.keys(data).length != 0 &&
                        <LeaderboardDashboard dashboardData={data} loadingData={loading} fetchData={fetchData} />}
                </div>
            </div>
        </>
    );
};

// CSS styles as JavaScript objects
const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '8px',
    width: 'calc(30% - 10px)',
    marginBottom: '20px',
    textAlign: 'center',
    flex: '1 1 200px',
};

const iconStyle = {
    fontSize: '40px',
    marginBottom: '10px',
    color: '#3f51b5',
};

const titleStyle = {
    fontSize: '20px',
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '10px',
};

const textStyle = {
    fontSize: '16px',
    color: '#666',
    marginBottom: '15px',
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
};

export default CompanyDashboard;

